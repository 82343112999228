import React, { useEffect } from 'react';
import * as Styled from './desktop-view.styles';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@innowise-group/mui-kit';
import { SelectOption, useUnitsAPI, useUnitsAdministrationOptions } from '@innowise-group/core';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, LabeledCheckbox, VacancySelect } from '@shared-mui-components';
import { LocalizedRequestItemResponse, RequestItem, useOptionsApi, useProfileAPI } from '@innowise-group/core';
import { RolesValues, ManagerRolesArray } from '@constants';

interface DesktopViewProps {
  isEdit?: boolean;
  isStatusDisabled?: boolean;
  requestStatusOptions: SelectOption[];
  disabled: boolean;
  locationCityOptions: SelectOption[];
  currentRequestEdition: LocalizedRequestItemResponse;
  locationCountryOptions: SelectOption[];
  offices: SelectOption[];
  onSubmit: (data: RequestItem) => void;
  handleClose: () => void;
  searchVacancyHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  groupBy: (value) => (option) => string;
  handleChange: (onChange: (value: string) => void) => (value: string) => void;
  requestAuthorOptions: SelectOption[];
  searchRequestAuthorOptions: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  isVacancyDisabled: boolean;
}

const DesktopView: React.FC<DesktopViewProps> = ({
  requestStatusOptions,
  isStatusDisabled,
  isEdit,
  locationCountryOptions,
  disabled,
  locationCityOptions,
  offices,
  currentRequestEdition,
  searchVacancyHandler,
  groupBy,
  handleChange,
  searchRequestAuthorOptions,
  requestAuthorOptions,
  isVacancyDisabled,
}) => {
  const { t } = useTranslation();
  const { profile } = useProfileAPI();
  const unitsOptions = useUnitsAdministrationOptions();
  const { isLoading: isUnitsLoading } = useUnitsAPI();
  const { workloadsOptions, workFormatsOptions, employmentFormsOptions, currencyOptions } = useOptionsApi();

  const { control, watch, trigger } = useFormContext<RequestItem>();
  const countries = watch('countries');
  const cities = watch('cities');
  const vacancy = watch('vacancyId');
  const currency = watch('currency');

  const handleChangeSalaryLimit =
    (fieldsName: keyof RequestItem, onChange: (data: React.ChangeEvent<HTMLInputElement>) => void) =>
    (data: React.ChangeEvent<HTMLInputElement>) => {
      onChange(data);
      trigger(fieldsName);
    };

  useEffect(() => {
    trigger(['expectedSalaryMin', 'expectedSalaryMax']);
  }, [trigger, currency]);

  return (
    <Styled.FormContainer>
      <Controller
        name="authorId"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => {
          return (
            <Select
              value={value}
              onChange={onChange}
              disableClearable={!value?.length}
              options={requestAuthorOptions}
              disabled={[RolesValues.Supervisor, ...ManagerRolesArray].includes(profile.role.name)}
              fullWidth
              multiple
              disableCloseOnSelect
              limitTags={1}
              renderInput={(params) => {
                return (
                  <Input
                    {...params}
                    fullWidth
                    onChange={searchRequestAuthorOptions}
                    label={t('pages.requests.creationModal.customer')}
                    placeholder={t('pages.candidates.statuses.selectAColleague')}
                    error={!!errors?.authorId?.message?.toString()}
                    helperText={errors?.authorId?.message?.toString()}
                  />
                );
              }}
            />
          );
        }}
      />
      <Styled.VacancyContainer>
        <Controller
          name="vacancyId"
          control={control}
          render={({ field: { onChange, value }, formState: { errors } }) => {
            return (
              <VacancySelect
                value={value}
                onChange={handleChange(onChange)}
                disabled={
                  isVacancyDisabled ||
                  disabled ||
                  (isEdit && [RolesValues.Supervisor, ...ManagerRolesArray].includes(profile.role.name))
                }
                groupBy={groupBy(value)}
                placeholder={t('pages.requests.creationModal.selectTheVacancyPlaceholder')}
                renderInput={(params) => {
                  return (
                    <Styled.DisabledInput
                      {...params}
                      required
                      onChange={searchVacancyHandler}
                      label={t('pages.requests.creationModal.vacancy')}
                      placeholder={t('pages.requests.creationModal.selectTheVacancyPlaceholder')}
                      error={!!errors?.vacancyId?.message}
                      helperText={errors?.vacancyId?.message.toString()}
                    />
                  );
                }}
              />
            );
          }}
        />
      </Styled.VacancyContainer>
      <Controller
        name="structuralUnit"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={unitsOptions}
            disabled={disabled}
            loading={isUnitsLoading}
            renderInput={(params) => {
              return (
                <Input
                  {...params}
                  required
                  label={t('pages.profile.profileDetails.department')}
                  placeholder={t('pages.candidates.offer.orgUnitPlaceholder')}
                  error={!!errors?.structuralUnit?.message}
                  helperText={errors?.structuralUnit?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      <Controller
        name="vacancyRequestStatusId"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={requestStatusOptions}
            disabled={isEdit && isStatusDisabled}
            renderInput={(params) => {
              return (
                <Input
                  {...params}
                  required
                  label={t('pages.requests.creationModal.status')}
                  placeholder={t('pages.requests.creationModal.selectStatus')}
                  error={!!errors?.vacancyRequestStatusId?.message}
                  helperText={errors?.vacancyRequestStatusId?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      <Controller
        name="hiringDeadline"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Styled.DatePickerContainer>
            <DatePicker
              selected={value ? new Date(value) : null}
              onChange={onChange}
              error={!!errors?.hiringDeadline?.message}
              isConstrained={false}
              helperText={errors?.hiringDeadline?.message.toString()}
              label={t('pages.requests.creationModal.deadline')}
              placeholder={t('pages.requests.creationModal.date')}
              minDate={new Date()}
            />
          </Styled.DatePickerContainer>
        )}
      />
      <Controller
        name="requiredCount"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Styled.FullNumberInput
            label={t('pages.requests.creationModal.fullCount')}
            required
            min={currentRequestEdition?.candidatesFound || 1}
            max={50}
            value={value}
            onChange={onChange}
            error={!!errors?.requiredCount?.message}
            helperText={errors?.requiredCount?.message.toString()}
            onStep={onChange}
          />
        )}
      />
      <Controller
        name="asEmployee"
        control={control}
        render={({ field: { onChange, value } }) => (
          <LabeledCheckbox
            disabled={disabled}
            onChange={onChange}
            checked={value}
            label={t('pages.requests.viewModal.asEmployee')}
          />
        )}
      />
      <Controller
        name="onProject"
        control={control}
        render={({ field: { onChange, value } }) => (
          <LabeledCheckbox
            disabled={disabled}
            onChange={onChange}
            checked={value}
            label={t('pages.requests.viewModal.onProject')}
          />
        )}
      />
      <Controller
        name="countries"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={locationCountryOptions}
            disabled={disabled || !vacancy}
            multiple
            limitTags={1}
            disableCloseOnSelect
            disableSortByAlphabet={false}
            renderInput={(params) => {
              return (
                <Input
                  {...params}
                  label={t('pages.requests.creationModal.location')}
                  placeholder={t('pages.requests.creationModal.selectLocation')}
                  error={!!errors?.countries?.message}
                  helperText={errors?.countries?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      <Controller
        name="cities"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={locationCityOptions}
            disabled={disabled || !countries?.length}
            limitTags={1}
            disableCloseOnSelect
            multiple
            disableSortByAlphabet={false}
            renderInput={(params) => {
              return (
                <Input
                  {...params}
                  label={t('pages.vacancies.creationModal.city')}
                  placeholder={t('pages.vacancies.creationModal.selectTheCity')}
                  error={!!errors?.cities?.message}
                  helperText={errors?.cities?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      <Controller
        name="offices"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={offices || []}
            disabled={disabled || !cities.length}
            disableSortByAlphabet={false}
            limitTags={1}
            disableCloseOnSelect
            multiple
            renderInput={(params) => {
              return (
                <Input
                  {...params}
                  label={t('pages.requests.creationModal.office')}
                  placeholder={t('pages.requests.creationModal.selectOffice')}
                  error={!!errors?.offices?.message}
                  helperText={errors?.offices?.message.toString()}
                />
              );
            }}
          />
        )}
      />
      <Controller
        name="expectedSalaryMin"
        control={control}
        render={({ field: { onChange, value, ref }, formState: { errors } }) => (
          <Input
            disabled={disabled}
            label={`${t('pages.candidates.candidateCreation.salary')} min`}
            error={!!errors?.expectedSalaryMin?.message}
            helperText={errors?.expectedSalaryMin?.message}
            value={value}
            inputRef={ref}
            onChange={handleChangeSalaryLimit('expectedSalaryMax', onChange)}
            placeholder={t('pages.candidates.candidateCreation.enterAValue')}
          />
        )}
      />
      <Controller
        name="expectedSalaryMax"
        control={control}
        render={({ field: { onChange, value, ref }, formState: { errors } }) => (
          <Input
            disabled={disabled}
            label={`${t('pages.candidates.candidateCreation.salary')} max`}
            error={!!errors?.expectedSalaryMax?.message}
            helperText={errors?.expectedSalaryMax?.message}
            value={value}
            inputRef={ref}
            onChange={handleChangeSalaryLimit('expectedSalaryMin', onChange)}
            placeholder={t('pages.candidates.candidateCreation.enterAValue')}
          />
        )}
      />
      <Controller
        name="currency"
        control={control}
        render={({ field: { onChange, value, ref }, formState: { errors } }) => (
          <Select
            disabled={disabled}
            value={value}
            onChange={onChange}
            options={currencyOptions || []}
            disableClearable
            disableSortByAlphabet={false}
            renderInput={(params) => (
              <Input
                {...params}
                required
                inputRef={ref}
                label={t('pages.candidates.candidateCreation.currency')}
                placeholder={t('pages.candidates.candidateCreation.selectCurrency')}
                helperText={errors?.currency?.message?.toString()}
                error={!!errors?.currency?.message}
              />
            )}
          />
        )}
      />
      <Styled.CheckboxesColumn>
        <Styled.FieldsGroup>
          <Styled.GroupLabel>{t('pages.candidates.candidateDetails.employmentType')}</Styled.GroupLabel>
          {workloadsOptions?.map((item, index) => (
            <Controller
              name={`workloads.${index}.checked`}
              control={control}
              key={item.value}
              render={({ field: { value, onChange } }) => (
                <LabeledCheckbox disabled={disabled} onChange={onChange} checked={value} label={item.title} />
              )}
            />
          ))}
        </Styled.FieldsGroup>
      </Styled.CheckboxesColumn>
      <Styled.CheckboxesColumn>
        <Styled.FieldsGroup>
          <Styled.GroupLabel>{t('pages.candidates.candidateDetails.formatOfWork')}</Styled.GroupLabel>
          {workFormatsOptions?.map((item, index) => (
            <Controller
              name={`workFormats.${index}.checked`}
              control={control}
              key={item.value}
              render={({ field: { value, ...props } }) => (
                <LabeledCheckbox disabled={disabled} checked={value} label={item.title} {...props} />
              )}
            />
          ))}
        </Styled.FieldsGroup>
      </Styled.CheckboxesColumn>
      <Styled.CheckboxesColumn>
        <Styled.FieldsGroup>
          <Styled.GroupLabel>{t('pages.requests.viewModal.formOfEmployment')}</Styled.GroupLabel>
          {employmentFormsOptions?.map((item, index) => (
            <Controller
              name={`employmentForms.${index}.checked`}
              control={control}
              key={item.value}
              render={({ field: { value, ...props } }) => (
                <LabeledCheckbox disabled={disabled} checked={value} label={item.title} {...props} />
              )}
            />
          ))}
        </Styled.FieldsGroup>
      </Styled.CheckboxesColumn>
      <Styled.CommentContainer>
        <Controller
          name="comment"
          control={control}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <Input
              fullWidth
              value={value}
              onChange={onChange}
              label={t('pages.candidates.candidateCreation.comment')}
              error={!!errors?.['comment']?.message}
              helperText={errors?.['comment']?.message.toString()}
              rows={7}
              multiline
              disabled={disabled}
            />
          )}
        />
        <Controller
          name="isCommentSecured"
          control={control}
          render={({ field: { onChange, value } }) => (
            <LabeledCheckbox
              onChange={onChange}
              checked={value}
              label={t('pages.requests.creationModal.restrictAccess')}
            />
          )}
        />
      </Styled.CommentContainer>
    </Styled.FormContainer>
  );
};

export default DesktopView;
