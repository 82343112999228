import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import {
  AppLanguages,
  AppNameLanguages,
  AppRoutes,
  CandidateStatusGroupEnum,
  MaxSubStatusesCount,
  StatusLifecycleStatuses,
} from '@constants';
import * as Styled from './administration-status-form.styles';
import { LabeledCheckbox } from '@shared-mui-components';
import { Input, Select, Icon } from '@innowise-group/mui-kit';
import {
  AdministrationStatusItem,
  useAdministrationStatusesAPI,
  useControlFormField,
  useValidationSchemaAdministrationStatus,
} from '@innowise-group/core';
import { components } from './administration-status-form.data';
import { useNavigate } from 'react-router-dom';

interface AdministrationStatusFormProps {
  isEdit?: boolean;
  onSubmit: (data: AdministrationStatusItem) => void;
}

const AdministrationStatusForm: React.FC<AdministrationStatusFormProps> = ({ onSubmit, isEdit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { statusesPhaseOptions } = useAdministrationStatusesAPI();
  const { validationSchema } = useValidationSchemaAdministrationStatus();
  const { handleAddItem, handleRemoveItem, handleChangeInputItem } = useControlFormField(validationSchema);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    trigger,
    formState: { isValid },
  } = useFormContext<AdministrationStatusItem>();
  const actualSubStatuses = watch('subStatusNames');
  const deletedSubStatuses = watch('deletedSubStatusNames');
  const isSubStatusExist = watch('SUB_STATUS').isExist;
  const file = watch('FILE');
  const feedback = watch('FEEDBACK');
  const group = watch('group');

  const deleteItemHandler = (index: number) => () => {
    handleRemoveItem(index, 'subStatusNames', actualSubStatuses)();
    if (actualSubStatuses[index].id) {
      setValue(`deletedSubStatusNames`, [
        ...deletedSubStatuses,
        { ...actualSubStatuses[index], lifecycleStatus: StatusLifecycleStatuses.Deleted },
      ]);
      trigger('subStatusNames');
    }
  };

  const removeItemHandler = (index: number) => () => {
    if (actualSubStatuses?.length < MaxSubStatusesCount) {
      handleRemoveItem(index, 'deletedSubStatusNames', deletedSubStatuses)();
      setValue(`subStatusNames`, [
        ...actualSubStatuses,
        { ...deletedSubStatuses[index], lifecycleStatus: StatusLifecycleStatuses.Actual },
      ]);
      trigger('subStatusNames');
    }
  };

  const addItemHandler = () => {
    handleAddItem('subStatusNames', actualSubStatuses, {
      names: { RUSSIAN: '', ENGLISH: '' },
      id: '',
      lifecycleStatus: StatusLifecycleStatuses.Actual,
    })();
    trigger('subStatusNames');
  };

  const handleNavigateBack = () => {
    navigate(AppRoutes.AdministrationStatuses);
  };

  const handleFieldChange =
    (
      isExist: boolean,
      isMandatory: boolean,
      name: string,
      onChange: (value: { isExist: boolean; isMandatory: boolean }) => void,
    ) =>
    () => {
      if (name === 'FILE') {
        setValue('FEEDBACK', { isExist: false, isMandatory: false });
      }
      if (name === 'FEEDBACK') {
        setValue('FILE', { isExist: false, isMandatory: false });
      }
      onChange({ isExist, isMandatory });
    };

  const getDisabledProps = (name: string) => {
    if (isEdit) return true;
    if (name === 'VACANCY') {
      return group === CandidateStatusGroupEnum.OFFER || group === CandidateStatusGroupEnum.JOB_ENTRY;
    }
    if (name === 'FILE') {
      return feedback.isExist;
    }
    if (name === 'FEEDBACK') {
      return file.isExist;
    }
    return false;
  };

  useEffect(() => {
    if (group === CandidateStatusGroupEnum.OFFER || group === CandidateStatusGroupEnum.JOB_ENTRY) {
      setValue('VACANCY', { isExist: true, isMandatory: true });
    }
  }, [group]);

  return (
    <Styled.FormContainer>
      <Styled.MainForm>
        <Styled.StatusContainer>
          <Styled.BlockTitle>{t('pages.administration.statuses.statusSettings')}</Styled.BlockTitle>
          {Object.entries(AppNameLanguages).map(([key, item]) => {
            return (
              <Controller
                name={`statusName.${item}`}
                control={control}
                key={`statusName.${item}`}
                render={({ field: { onChange, value }, formState: { errors } }) => {
                  return (
                    <Input
                      value={value}
                      required
                      onChange={onChange}
                      error={!!errors.statusName?.[item]?.message}
                      helperText={errors.statusName?.[item]?.message}
                      label={`${t('pages.administration.statuses.statusName')} ${
                        key !== AppLanguages.Ru ? `(${key.toUpperCase()})` : ''
                      }`}
                      placeholder={t('pages.administration.statuses.enterTheName')}
                    />
                  );
                }}
              />
            );
          })}
          <Controller
            name="group"
            control={control}
            render={({ field: { onChange, value }, formState: { errors } }) => {
              return (
                <Select
                  value={value}
                  onChange={onChange}
                  disableClearable={!value}
                  disabled={isEdit}
                  options={statusesPhaseOptions || []}
                  disableSortByAlphabet={false}
                  renderInput={(params) => {
                    return (
                      <Input
                        {...params}
                        required
                        error={!!errors.group?.message}
                        helperText={errors.group?.message?.toString()}
                        label={t('pages.administration.statuses.statusPhase')}
                        placeholder={t('pages.administration.statuses.selectThePhase')}
                      />
                    );
                  }}
                />
              );
            }}
          />
        </Styled.StatusContainer>
        <Styled.ComponentsContainer>
          <Styled.BlockTitle>
            <div>{t('pages.administration.statuses.components')}</div>
            <div>{t('pages.administration.statuses.required')}</div>
          </Styled.BlockTitle>
          <Styled.ComponentsBlock>
            {components.map(({ title, fields }, index) => {
              return (
                <React.Fragment key={`row.${index}`}>
                  {title && <Styled.ComponentsBlockTitle>{t(title)}</Styled.ComponentsBlockTitle>}
                  {fields.map(({ name, label }) => (
                    <Controller
                      name={name}
                      control={control}
                      key={`${name}_${index}`}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Styled.ComponentRow>
                            <LabeledCheckbox
                              onChange={handleFieldChange(!(value?.isExist || false), false, name, onChange)}
                              checked={value?.isExist || false}
                              disabled={getDisabledProps(name)}
                              label={t(label)}
                            />
                            <Styled.Switch
                              checked={value?.isMandatory || false}
                              disabled={!value?.isExist || getDisabledProps(name)}
                              onChange={handleFieldChange(true, !(value?.isMandatory || false), name, onChange)}
                            />
                          </Styled.ComponentRow>
                        );
                      }}
                    />
                  ))}
                </React.Fragment>
              );
            })}
          </Styled.ComponentsBlock>
        </Styled.ComponentsContainer>
      </Styled.MainForm>
      <Styled.VerticalDivider />
      <Styled.SubStatusesContainer>
        <Styled.SubStatusesFieldsContainer>
          {isSubStatusExist ? (
            <React.Fragment>
              <Styled.SubStatusBlockTitle>
                {t('pages.administration.statuses.substatusSettings')}
              </Styled.SubStatusBlockTitle>
              {!!actualSubStatuses?.length &&
                actualSubStatuses.map((item, index) => {
                  return (
                    <Styled.SubStatusRow key={index}>
                      {Object.entries(AppNameLanguages).map(([key, lang]) => {
                        return (
                          <Controller
                            name={`subStatusNames.${index}.names.${lang}`}
                            control={control}
                            key={`subStatusNames.${index}.names.${lang}`}
                            render={({ field: { onChange, value }, formState: { errors } }) => {
                              return (
                                <Styled.SubStatusInput
                                  required={index < 2}
                                  value={value}
                                  onChange={handleChangeInputItem(onChange, 'subStatusNames')}
                                  fullWidth
                                  label={`${t('pages.administration.statuses.subStatusName')} ${
                                    index ? index + 1 : ''
                                  } ${key !== AppLanguages.Ru ? `(${key.toUpperCase()})` : ''}`}
                                  placeholder={t('pages.administration.statuses.enterTheName')}
                                  error={
                                    !!(
                                      errors?.subStatusNames?.[index]?.names?.[lang]?.message ||
                                      errors?.subStatusNames?.[index]?.names?.message ||
                                      errors?.subStatusNames?.[index]?.message ||
                                      errors?.subStatusNames?.message
                                    )
                                  }
                                  helperText={(
                                    errors?.subStatusNames?.[index]?.names?.[lang]?.message ||
                                    errors?.subStatusNames?.[index]?.names?.message ||
                                    errors?.subStatusNames?.[index]?.message ||
                                    errors?.subStatusNames?.message
                                  )?.toString()}
                                />
                              );
                            }}
                          />
                        );
                      })}
                      <Styled.DeleteIconContainer>
                        {actualSubStatuses.length > 1 && item.id ? (
                          <Styled.ArchiveIcon type="u_delete" viewBox="-2 0 22 22" onClick={deleteItemHandler(index)} />
                        ) : (
                          <Styled.DeleteIcon type="u_plus" onClick={deleteItemHandler(index)} />
                        )}
                      </Styled.DeleteIconContainer>
                    </Styled.SubStatusRow>
                  );
                })}
              {actualSubStatuses?.length < MaxSubStatusesCount && (
                <Styled.Button onClick={addItemHandler} variant="text" startIcon={<Icon type="u_plus" size={14} />}>
                  {t('pages.administration.statuses.subStatusCell')}
                </Styled.Button>
              )}
              {isEdit && (
                <React.Fragment>
                  <Styled.SubStatusBlockTitle>{t('pages.candidates.archive')}</Styled.SubStatusBlockTitle>
                  {!!deletedSubStatuses?.length &&
                    deletedSubStatuses.map((item, index) => {
                      return (
                        <Styled.SubStatusRow key={index}>
                          {Object.entries(AppNameLanguages).map(([key, lang]) => {
                            return (
                              <Styled.SubStatusInput
                                value={item.names[lang]}
                                key={`${item.id}_${key}`}
                                disabled
                                fullWidth
                                label={`${t('pages.administration.statuses.subStatusName')} ${
                                  key !== AppLanguages.Ru ? `(${key.toUpperCase()})` : ''
                                }`}
                                placeholder={t('pages.administration.statuses.enterTheName')}
                              />
                            );
                          })}
                          <Styled.DeleteIconContainer>
                            <Styled.DeleteIcon
                              type="u_restore"
                              size={20}
                              viewBox="0 0 26 26"
                              onClick={removeItemHandler(index)}
                              disabled={actualSubStatuses?.length >= MaxSubStatusesCount}
                            />
                          </Styled.DeleteIconContainer>
                        </Styled.SubStatusRow>
                      );
                    })}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <div />
          )}
        </Styled.SubStatusesFieldsContainer>
        <Styled.ButtonsContainer>
          <Styled.Button variant="outlined" onClick={handleNavigateBack}>
            {t('buttons.cancel')}
          </Styled.Button>
          <Styled.Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
            {t('buttons.save')}
          </Styled.Button>
        </Styled.ButtonsContainer>
      </Styled.SubStatusesContainer>
    </Styled.FormContainer>
  );
};

export default AdministrationStatusForm;
