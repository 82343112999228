import { createReducer } from '@reduxjs/toolkit';
import { preloadedCandidatesState } from './candidates.preloaded-state';
import {
  checkCandidatesDuplicatesThunk,
  getAllCandidatesThunk,
  getCandidateByIdThunk,
  getCandidateByIdSilentThunk,
  createCandidateThunk,
  updateCandidateThunk,
  createCandidateCommentThunk,
  updateCandidateCommentThunk,
  createCandidateFileIdThunk,
  deleteCandidateFileIdThunk,
  getCandidateFilesIdsThunk,
  getCandidateFileDataThunk,
  linkCandidateFileThunk,
  createCandidateAvatarPhotoThunk,
  getCandidatePdfParsedInfoThunk,
  getCandidateChangeHistoryThunk,
  duplicatesReportThunk,
  fetchCandidatesPairByIdThunk,
  createHistoryCommentThunk,
  getHistoryCommentThunk,
  updateHistoryCommentThunk,
  candidatesExportThunk,
  prioritizeCandidatesThunk,
  getPrioritizeCandidatesThunk,
  updateVacancyCandidatePriorityThunk,
  getStatusListThunk,
  createCandidateStatusThunk,
  updateCandidateStatusThunk,
  getCandidateEventsThunk,
  createCandidateThreadCommentThunk,
  updateCandidateThreadCommentThunk,
  resolveStatusClaimThunk,
  getCandidateActualStatusThunk,
  restoreAdminEventThunk,
  deleteCandidateCommentsThunk,
  restoreAdminChangeCommentThunk,
  deleteCandidateByIdThunk,
  restoreCandidateByIdThunk,
  mergeCandidateDuplicatesThunk,
  findExistingCandidateDuplicatesThunk,
  getPrioritizeCandidatesByVacancyIdThunk,
  createCandidateOfferThunk,
  getOffersByCandidateIdThunk,
  deleteCandidateOfferThunk,
  restoreCandidateOfferThunk,
  updateCandidateOfferThunk,
  fetchCandidateRequestClaimThunk,
  saveStatusesOrdersThunk,
} from './candidates.thunk';
import {
  clearCandidatesAction,
  toggleCandidateDupleAction,
  saveNewCandidateInfoAction,
  clearCandidateFilesIdsAction,
  clearCandidateFileAttachmentAction,
  clearCandidateAvatarAction,
  saveCandidatesDiffAction,
  updateCandidatePrimitiveValuesAction,
  updateCandidatesContactsDiffAction,
  addProfessionInToCandidatesDiffAction,
  updateProfessionNameInCandidatesDiffAction,
  updateProfessionGradeInCandidatesDiffAction,
  addLanguageInToCandidatesDiffAction,
  updateLanguageNameInCandidatesDiffAction,
  updateLanguageLevelInCandidatesDiffAction,
  addORDeleteCandidateDiffMultiSelectElemAction,
  setCandidateDiffMultiSelectElemAction,
  updateLocalizationEntityAction,
  setLocalizationEntityAction,
  updateVisaEntityAction,
  resetCandidateRelocationCountriesAction,
  resetCandidateVisasAction,
  setVisaEntityAction,
  updateCandidateSalaryAction,
  updateCandidateSalaryFieldAction,
  setWorkFormatsAndLoadsAction,
  manageCandidateMapDataAction,
  updateEntityFieldAction,
  resetCandidatesStateAction,
  clearCandidateHistoryAction,
  clearDuplicatesReportAction,
  deleteHistoryCommentAction,
  restoreHistoryCommentAction,
  clearRecruiterCandidateAction,
  manageCandidateConsolidationFilesAction,
  setCandidateMapDataAction,
  clearStatusListAction,
  clearCurrentStatusAction,
  clearCandidateEventsAction,
  deleteCommentAction,
  restoreCommentAction,
  setCurrentStatusItemAction,
  mergeCandidateAction,
  setPagableAction,
  setRecruiterModeConfigAction,
  clearActualStatusAction,
  manageWorkFormatsLoadsEmploymentsAction,
  removeProfessionsIdsFromCandidatesDiffAction,
  removeProfessionsIdsFromVisaEntityAction,
  setCurrentCandidateAction,
  deleteCandidateLinkAction,
  clearCandidatesOffersAction,
  clearCandidatesClaimAction,
} from './candidates.action';
import { getArrayIds, getArrayStringIds, getStringArrayIds, transformArrayToObject } from '@innowise-group/utilities';
import { CandidateChangesStatuses, EditCandidateMode, FormattedCandidateDiffMerge } from './candidates.types';
import { mapStatusItemResponseToStatusItem } from '@innowise-group/core';
import {
  candidateActivityPlaceMapper,
  candidateNestedFieldsAutoMergeMapper,
  candidateSelectDataFieldsMapper,
} from './candidates.mappers';
import {
  ClaimActionTypes,
  ClaimStatusTypes,
  LifecycleStatuses,
  activityPlaceFields,
  autoMergeCandidateFields,
  selectDataFields,
} from '@constants';
import { logoutAction } from '../common';
import { deleteStatusItemByIdThunk, restoreStatusItemByIdThunk } from '../administration/administration.thunk';

export const candidatesReducer = createReducer(preloadedCandidatesState, (builder) =>
  builder
    .addCase(restoreCandidateOfferThunk.fulfilled, (state, { payload }) => {
      state.offers.isLoading = false;
      if (state.offers.items[payload.id?.toString()]) {
        state.offers.items[payload.id?.toString()] = {
          ...state.offers.items[payload.id?.toString()],
          lifecycleStatus: LifecycleStatuses.Actual,
        };
      }
    })
    .addCase(restoreCandidateOfferThunk.pending, (state) => {
      state.offers.isLoading = true;
    })
    .addCase(restoreCandidateOfferThunk.rejected, (state) => {
      state.offers.isLoading = false;
    })

    .addCase(deleteCandidateOfferThunk.fulfilled, (state, { payload }) => {
      state.offers.isLoading = false;
      if (state.offers.items[payload.id?.toString()]) {
        state.offers.items[payload.id?.toString()] = {
          ...state.offers.items[payload.id?.toString()],
          lifecycleStatus: LifecycleStatuses.Deleted,
        };
      }
    })
    .addCase(deleteCandidateOfferThunk.pending, (state) => {
      state.offers.isLoading = true;
    })
    .addCase(deleteCandidateOfferThunk.rejected, (state) => {
      state.offers.isLoading = false;
    })

    .addCase(getOffersByCandidateIdThunk.fulfilled, (state, { payload }) => {
      state.offers.isLoading = false;
      state.offers.items = transformArrayToObject(payload);
      state.offers.ids = getStringArrayIds(payload);
    })
    .addCase(getOffersByCandidateIdThunk.pending, (state) => {
      state.offers.isLoading = true;
    })
    .addCase(getOffersByCandidateIdThunk.rejected, (state) => {
      state.offers.isLoading = false;
    })

    .addCase(updateCandidateOfferThunk.fulfilled, (state, { payload }) => {
      state.offers.isFormLoading = false;
      state.offers.items[payload.id.toString()] = payload;
    })
    .addCase(updateCandidateOfferThunk.pending, (state) => {
      state.offers.isFormLoading = true;
    })
    .addCase(updateCandidateOfferThunk.rejected, (state) => {
      state.offers.isFormLoading = false;
    })

    .addCase(createCandidateOfferThunk.fulfilled, (state, { payload }) => {
      state.offers.isFormLoading = false;
      state.offers.items = { ...state.offers.items, [payload.id.toString()]: payload };
      state.offers.ids = [payload.id.toString(), ...state.offers.ids];
    })
    .addCase(createCandidateOfferThunk.pending, (state) => {
      state.offers.isFormLoading = true;
    })
    .addCase(createCandidateOfferThunk.rejected, (state) => {
      state.offers.isFormLoading = false;
    })

    .addCase(restoreCandidateByIdThunk.fulfilled, (state, { meta }) => {
      state.candidates.isLoading = false;
      state.candidates.ids = state.candidates.ids.filter((item) => !meta.arg.ids.includes(item));
    })
    .addCase(restoreCandidateByIdThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(restoreCandidateByIdThunk.rejected, (state) => {
      state.candidates.isLoading = false;
    })
    .addCase(deleteCandidateByIdThunk.fulfilled, (state) => {
      state.candidates.isLoading = false;
    })
    .addCase(deleteCandidateByIdThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(deleteCandidateByIdThunk.rejected, (state) => {
      state.candidates.isLoading = false;
    })
    .addCase(resetCandidatesStateAction, (state) => {
      return {
        ...preloadedCandidatesState,
        recruiterMode: { ...state.recruiterMode },
        allStatuses: { ...state.allStatuses },
      };
    })
    .addCase(createCandidateAvatarPhotoThunk.fulfilled, (state, { payload }) => {
      state.candidates.isLoading = false;
      state.candidates.currentAvatarFile = payload;
    })
    .addCase(createCandidateAvatarPhotoThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(createCandidateAvatarPhotoThunk.rejected, (state) => {
      state.candidates.isLoading = false;
      state.candidates.currentAvatarFile = null;
    })
    .addCase(clearCandidateAvatarAction, (state) => {
      state.candidates.currentAvatarFile = null;
    })
    .addCase(createCandidateFileIdThunk.fulfilled, (state, { payload, meta }) => {
      if (!meta.arg.isStatusFile) {
        state.filesToAttach.ids.push(payload.id);
        state.filesToAttach.items[payload.id] = payload;
      }
      state.filesToAttach.isLoading = false;
    })
    .addCase(createCandidateFileIdThunk.pending, (state) => {
      state.filesToAttach.isLoading = true;
    })
    .addCase(createCandidateFileIdThunk.rejected, (state) => {
      state.filesToAttach.isLoading = false;
    })
    .addCase(deleteCandidateFileIdThunk.fulfilled, (state, { payload }) => {
      state.filesToAttach.isLoading = false;
      state.filesToAttach.ids = state.filesToAttach.ids.filter((item) => item !== payload);
      if (state.filesToAttach.items[payload]) delete state.filesToAttach.items[payload];
    })
    .addCase(deleteCandidateFileIdThunk.pending, (state) => {
      state.filesToAttach.isLoading = true;
    })
    .addCase(deleteCandidateFileIdThunk.rejected, (state) => {
      state.filesToAttach.isLoading = false;
    })
    .addCase(clearCandidateFilesIdsAction, (state) => {
      state.filesToAttach = preloadedCandidatesState.filesToAttach;
      state.files = preloadedCandidatesState.files;
    })
    .addCase(getCandidateFilesIdsThunk.fulfilled, (state, { payload }) => {
      state.files.isLoading = false;
      state.files.items = transformArrayToObject(payload);
      state.files.ids = getArrayIds(payload);
    })
    .addCase(getCandidateFilesIdsThunk.pending, (state) => {
      state.files.isLoading = true;
    })
    .addCase(getCandidateFilesIdsThunk.rejected, (state) => {
      state.files.isLoading = false;
    })

    .addCase(getCandidateFileDataThunk.fulfilled, (state, { payload }) => {
      state.files.isCurrentFileLoading = false;
      state.files.currentFile = payload || null;
    })
    .addCase(getCandidateFileDataThunk.pending, (state) => {
      state.files.isCurrentFileLoading = true;
    })
    .addCase(getCandidateFileDataThunk.rejected, (state) => {
      state.files.isCurrentFileLoading = false;
    })
    .addCase(clearCandidateFileAttachmentAction, (state) => {
      state.files.currentFile = null;
    })
    .addCase(linkCandidateFileThunk.fulfilled, (state, { payload }) => {
      state.files.isLoading = false;
      state.candidates.currentCandidate = payload;
    })
    .addCase(linkCandidateFileThunk.pending, (state) => {
      state.files.isLoading = true;
    })
    .addCase(linkCandidateFileThunk.rejected, (state) => {
      state.files.isLoading = false;
    })
    .addCase(createCandidateThunk.fulfilled, (state) => {
      state.candidates.isLoading = false;
    })
    .addCase(createCandidateThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(createCandidateThunk.rejected, (state) => {
      state.candidates.isLoading = false;
    })

    .addCase(checkCandidatesDuplicatesThunk.fulfilled, (state, { payload }) => {
      // @ts-expect-error id exists
      state.candidatesConsolidation.candidateDuplicates = transformArrayToObject(payload.duplicates);
      // @ts-expect-error id exists
      state.candidatesConsolidation.candidateDuplicatesIds = getArrayIds(payload.duplicates);
      state.candidatesConsolidation.isCheckedDuplicates = !!payload.duplicates.length;
      state.candidates.isLoading = false;
    })
    .addCase(checkCandidatesDuplicatesThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(checkCandidatesDuplicatesThunk.rejected, (state) => {
      state.candidates.isLoading = false;
    })

    .addCase(findExistingCandidateDuplicatesThunk.fulfilled, (state) => {
      state.candidatesConsolidation.isCheckedDuplicates = true;
      state.candidates.isLoading = false;
    })
    .addCase(findExistingCandidateDuplicatesThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(findExistingCandidateDuplicatesThunk.rejected, (state) => {
      state.candidates.isLoading = false;
    })

    .addCase(updateCandidateThunk.fulfilled, (state) => {
      state.candidates.isLoading = false;
      state.files = preloadedCandidatesState.files;
      state.filesToAttach = preloadedCandidatesState.filesToAttach;
    })
    .addCase(updateCandidateThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(updateCandidateThunk.rejected, (state) => {
      state.candidates.isLoading = false;
    })

    .addCase(mergeCandidateDuplicatesThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(mergeCandidateDuplicatesThunk.fulfilled, (state) => {
      state.candidates.isLoading = false;
    })
    .addCase(mergeCandidateDuplicatesThunk.rejected, (state) => {
      state.candidates.isLoading = false;
    })

    .addCase(getAllCandidatesThunk.fulfilled, (state, { payload }) => {
      state.candidates.items = transformArrayToObject(payload?.content);
      state.candidates.ids = getArrayIds(payload?.content);
      state.candidates.isLoading = false;
      state.candidates.totalElements = payload.totalElements;
      state.candidates.totalPages = payload.totalPages;
    })
    .addCase(getAllCandidatesThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(getAllCandidatesThunk.rejected, (state) => {
      state.candidates = preloadedCandidatesState.candidates;
    })
    .addCase(getCandidateByIdThunk.fulfilled, (state, { payload }) => {
      state.candidates.currentCandidate = payload;
      state.candidates.isLoading = false;
    })
    .addCase(getCandidateByIdThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(getCandidateByIdThunk.rejected, (state) => {
      state.candidates = preloadedCandidatesState.candidates;
    })
    .addCase(getCandidateByIdSilentThunk.fulfilled, (state, { payload }) => {
      state.candidates.currentCandidate = payload;
    })
    .addCase(getCandidateByIdSilentThunk.rejected, (state) => {
      state.candidates = preloadedCandidatesState.candidates;
    })
    .addCase(setCurrentCandidateAction, (state, { payload }) => {
      state.candidates.currentCandidate = payload;
    })
    .addCase(manageCandidateConsolidationFilesAction, (state, { payload }) => {
      if (payload.mode === EditCandidateMode.Add) {
        state.candidatesConsolidation.candidatesDiffs.filesMeta.push(payload.file);
      }
      if (payload.mode === EditCandidateMode.Delete) {
        state.candidatesConsolidation.candidatesDiffs.filesMeta =
          state.candidatesConsolidation.candidatesDiffs.filesMeta.filter((file) => file.id !== payload.file.id);
      }
    })
    .addCase(deleteCandidateLinkAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateLinks.preselected =
        state.candidatesConsolidation.candidatesDiffs.candidateLinks.preselected.filter(({ name, url }) => {
          return url !== payload.url && name !== payload.url;
        });
    })
    .addCase(toggleCandidateDupleAction, (state, { payload: duplicateID }) => {
      state.candidatesConsolidation.candidateDuplicatesIds =
        state.candidatesConsolidation.candidateDuplicatesIds.filter((id) => id !== duplicateID);
    })
    .addCase(saveNewCandidateInfoAction, (state, { payload }) => {
      state.candidatesConsolidation.unsavedCandidate = payload;
    })
    .addCase(clearCandidatesAction, (state) => {
      state.candidates = preloadedCandidatesState.candidates;
    })
    .addCase(createCandidateCommentThunk.fulfilled, (state, { payload }) => {
      state.events.ids = [payload.id, ...state.events.ids];
      state.events.items[payload.id] = payload;
      state.events.isLoading = false;
    })
    .addCase(createCandidateCommentThunk.pending, (state) => {
      state.events.isLoading = true;
    })
    .addCase(createCandidateCommentThunk.rejected, (state) => {
      state.events.isLoading = false;
    })
    .addCase(updateCandidateCommentThunk.fulfilled, (state, { payload }) => {
      state.events.isLoading = false;
      state.events.items[payload.id] = payload;
    })
    .addCase(updateCandidateCommentThunk.pending, (state) => {
      state.events.isLoading = true;
    })
    .addCase(updateCandidateCommentThunk.rejected, (state) => {
      state.events.isLoading = false;
    })
    .addCase(getCandidatePdfParsedInfoThunk.fulfilled, (state) => {
      state.candidates.isLoading = false;
    })
    .addCase(getCandidatePdfParsedInfoThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(getCandidatePdfParsedInfoThunk.rejected, (state) => {
      state.candidates.isLoading = false;
    })
    .addCase(saveCandidatesDiffAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs = payload.candidatesDiffs;
      state.candidatesConsolidation.targetCandidate = payload.targetCandidate;
      state.candidatesConsolidation.updatesCandidate = payload.updatesCandidate;
    })
    .addCase(updateCandidatePrimitiveValuesAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs[payload.field].preselected = payload.value;
      state.candidatesConsolidation.candidatesDiffs[payload.field].status = CandidateChangesStatuses.Resolved;
    })
    .addCase(updateCandidatesContactsDiffAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateContacts[payload.field].preselected = payload.value;
      state.candidatesConsolidation.candidatesDiffs.candidateContacts[payload.field].status =
        CandidateChangesStatuses.Resolved;
    })
    .addCase(addProfessionInToCandidatesDiffAction, (state, { payload }) => {
      if (payload.mode === EditCandidateMode.Add) {
        state.candidatesConsolidation.candidatesDiffs.candidateProfessions.preselected.push(payload.updates);
      }
      if (payload.source) {
        state.candidatesConsolidation.candidatesDiffs.candidateProfessions[payload.source as string] =
          state.candidatesConsolidation.candidatesDiffs.candidateProfessions[payload.source as string].filter(
            ({ professionId, gradeId }) =>
              !(professionId === payload.updates.professionId && gradeId === payload.updates.gradeId),
          );
      }
      if (
        !state.candidatesConsolidation.candidatesDiffs.candidateProfessions.target.length &&
        !state.candidatesConsolidation.candidatesDiffs.candidateProfessions.updates.length
      ) {
        state.candidatesConsolidation.candidatesDiffs.candidateProfessions.status = CandidateChangesStatuses.Resolved;
      }
    })
    .addCase(removeProfessionsIdsFromCandidatesDiffAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateProfessions[payload.source as string] =
        state.candidatesConsolidation.candidatesDiffs.candidateProfessions[payload.source as string].map((profession) =>
          payload.ids.includes(profession.id)
            ? {
                professionId: profession.professionId,
                gradeId: profession.gradeId,
              }
            : profession,
        );
    })
    .addCase(updateProfessionNameInCandidatesDiffAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateProfessions.preselected[payload.index] = {
        ...state.candidatesConsolidation.candidatesDiffs.candidateProfessions.preselected[payload.index],
        professionId: payload.professionId,
      };
    })
    .addCase(updateProfessionGradeInCandidatesDiffAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateProfessions.preselected[payload.index] = {
        ...state.candidatesConsolidation.candidatesDiffs.candidateProfessions.preselected[payload.index],
        gradeId: payload.gradeId,
      };
    })

    .addCase(addLanguageInToCandidatesDiffAction, (state, { payload }) => {
      if (payload.mode === EditCandidateMode.Add) {
        state.candidatesConsolidation.candidatesDiffs.candidateLanguages.preselected.push(payload.updates);
      }
      if (payload.source) {
        state.candidatesConsolidation.candidatesDiffs.candidateLanguages[payload.source as string] =
          state.candidatesConsolidation.candidatesDiffs.candidateLanguages[payload.source as string].filter(
            ({ languageId, languageLevelId }) =>
              !(languageId === payload.updates.languageId && languageLevelId === payload.updates.languageLevelId),
          );
      }
      if (
        !state.candidatesConsolidation.candidatesDiffs.candidateLanguages.target.length &&
        !state.candidatesConsolidation.candidatesDiffs.candidateLanguages.updates.length
      ) {
        state.candidatesConsolidation.candidatesDiffs.candidateLanguages.status = CandidateChangesStatuses.Resolved;
      }
    })
    .addCase(updateLanguageNameInCandidatesDiffAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateLanguages.preselected[payload.index] = {
        ...state.candidatesConsolidation.candidatesDiffs.candidateLanguages.preselected[payload.index],
        languageId: payload.languageId,
      };
    })
    .addCase(updateLanguageLevelInCandidatesDiffAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateLanguages.preselected[payload.index] = {
        ...state.candidatesConsolidation.candidatesDiffs.candidateLanguages.preselected[payload.index],
        languageLevelId: payload.languageLevel,
      };
    })

    .addCase(addORDeleteCandidateDiffMultiSelectElemAction, (state, { payload }) => {
      if (payload.mode === EditCandidateMode.Add) {
        state.candidatesConsolidation.candidatesDiffs[payload.entity].preselected.push(payload.updates);
      }
      state.candidatesConsolidation.candidatesDiffs[payload.entity][payload.source as string] =
        state.candidatesConsolidation.candidatesDiffs[payload.entity][payload.source as string].filter(
          ({ value }) => value !== payload.updates.value,
        );
      if (
        !state.candidatesConsolidation.candidatesDiffs[payload.entity].target.length &&
        !state.candidatesConsolidation.candidatesDiffs[payload.entity].updates.length
      ) {
        state.candidatesConsolidation.candidatesDiffs[payload.entity].status = CandidateChangesStatuses.Resolved;
      }
    })
    .addCase(setCandidateDiffMultiSelectElemAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs[payload.entity].preselected = payload.updates;
      state.candidatesConsolidation.candidatesDiffs[payload.entity].target =
        state.candidatesConsolidation.candidatesDiffs[payload.entity].target.filter((elem) =>
          payload.updates.some(({ value }) => value === elem.value),
        );
      state.candidatesConsolidation.candidatesDiffs[payload.entity].updates =
        state.candidatesConsolidation.candidatesDiffs[payload.entity].updates.filter((elem) =>
          payload.updates.some(({ value }) => value === elem.value),
        );
    })

    .addCase(updateLocalizationEntityAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateLocation[payload.entityName].preselected =
        state.candidatesConsolidation.candidatesDiffs.candidateLocation[payload.entityName][payload.source];
      state.candidatesConsolidation.candidatesDiffs.candidateLocation[payload.entityName].status =
        CandidateChangesStatuses.Resolved;
    })
    .addCase(setLocalizationEntityAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateLocation[payload.entityName].preselected = payload.val;
      state.candidatesConsolidation.candidatesDiffs.candidateLocation[payload.entityName].status =
        CandidateChangesStatuses.Resolved;
    })

    .addCase(updateVisaEntityAction, (state, { payload }) => {
      if (payload.mode === EditCandidateMode.Add) {
        state.candidatesConsolidation.candidatesDiffs.candidateVisas.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateVisas[payload.source];
        state.candidatesConsolidation.candidatesDiffs.visaAvailability.preselected =
          state.candidatesConsolidation.candidatesDiffs.visaAvailability[payload.source];
      }
      state.candidatesConsolidation.candidatesDiffs.visaAvailability.status = CandidateChangesStatuses.Resolved;
      state.candidatesConsolidation.candidatesDiffs.candidateVisas.status = CandidateChangesStatuses.Resolved;
    })
    .addCase(removeProfessionsIdsFromVisaEntityAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateVisas[payload].id = '';
    })
    .addCase(resetCandidateRelocationCountriesAction, (state) => {
      state.candidatesConsolidation.candidatesDiffs.candidateRelocationCountries.preselected = [];
      state.candidatesConsolidation.candidatesDiffs.candidateRelocationCountries.target = [];
      state.candidatesConsolidation.candidatesDiffs.candidateRelocationCountries.updates = [];
      state.candidatesConsolidation.candidatesDiffs.candidateRelocationCountries.status =
        CandidateChangesStatuses.Resolved;
    })
    .addCase(setVisaEntityAction, (state, { payload }) => {
      // TODO: update after package consolidation
      state.candidatesConsolidation.candidatesDiffs.candidateVisas = {
        target: { validTo: '', visaType: '', id: '', isFullVisaDate: false },
        updates: { validTo: '', visaType: '', id: '', isFullVisaDate: false },
        preselected: {
          ...state.candidatesConsolidation.candidatesDiffs.candidateVisas.preselected,
          [payload.entity]: payload.val,
        },
        status: CandidateChangesStatuses.Resolved,
      };
    })
    .addCase(resetCandidateVisasAction, (state) => {
      // TODO: update after package consolidation
      state.candidatesConsolidation.candidatesDiffs.candidateVisas = {
        target: { validTo: '', visaType: '', id: '', isFullVisaDate: false },
        updates: { validTo: '', visaType: '', id: '', isFullVisaDate: false },
        preselected: { validTo: '', visaType: '', id: '', isFullVisaDate: false },
        status: CandidateChangesStatuses.Resolved,
      };
    })

    .addCase(setWorkFormatsAndLoadsAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs[payload.entity].preselected =
        state.candidatesConsolidation.candidatesDiffs[payload.entity].preselected.some(
          (value) => value === payload.value,
        )
          ? state.candidatesConsolidation.candidatesDiffs[payload.entity].preselected.filter(
              (value) => value !== payload.value,
            )
          : [...state.candidatesConsolidation.candidatesDiffs[payload.entity].preselected, payload.value];
      state.candidatesConsolidation.candidatesDiffs[payload.entity].status = CandidateChangesStatuses.Resolved;
    })

    .addCase(manageWorkFormatsLoadsEmploymentsAction, (state, { payload }) => {
      if (payload.mode === EditCandidateMode.Add) {
        state.candidatesConsolidation.candidatesDiffs[payload.entity].preselected.push(payload.value);
      }
      state.candidatesConsolidation.candidatesDiffs[payload.entity][payload.source] =
        state.candidatesConsolidation.candidatesDiffs[payload.entity][payload.source].filter(
          (v) => v !== payload.value,
        );
    })

    .addCase(updateCandidateSalaryAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateSalary.currency.preselected = payload.currency;
      state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryMax.preselected = Number(
        payload.expectedSalaryMax,
      );
      state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryMin.preselected = Number(
        payload.expectedSalaryMin,
      );
      state.candidatesConsolidation.candidatesDiffs.candidateSalary.currency.status = CandidateChangesStatuses.Resolved;
      state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryMax.status =
        CandidateChangesStatuses.Resolved;
      state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryMin.status =
        CandidateChangesStatuses.Resolved;
    })

    .addCase(updateCandidateSalaryFieldAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs.candidateSalary[payload.field].preselected = payload.value;
      state.candidatesConsolidation.candidatesDiffs.candidateSalary[payload.field].status =
        CandidateChangesStatuses.Resolved;
    })

    .addCase(manageCandidateMapDataAction, (state, { payload }) => {
      const newData = Object.entries(payload.data).reduce((acc, [key, val]) => {
        return {
          ...acc,
          [key]: {
            preselected: val,
            target: val,
            updates: val,
            status: CandidateChangesStatuses.Resolved,
          },
        };
      }, {});
      if (payload.mode === EditCandidateMode.Add) {
        state.candidatesConsolidation.candidatesDiffs[payload.entity].preselected.push(newData as any);
      }
      if (payload.source) {
        state.candidatesConsolidation.candidatesDiffs[payload.entity][payload.source].splice(payload.index, 1);
      }
    })
    .addCase(updateEntityFieldAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs[payload.entity].preselected[payload.index][
        payload.field
      ].preselected =
        payload.value as unknown as FormattedCandidateDiffMerge[typeof payload.entity]['preselected'][number];
      state.candidatesConsolidation.candidatesDiffs[payload.entity].preselected[payload.index][payload.field].status =
        CandidateChangesStatuses.Resolved;
    })
    .addCase(setCandidateMapDataAction, (state, { payload }) => {
      state.candidatesConsolidation.candidatesDiffs[payload.entity].preselected =
        state.candidatesConsolidation.candidatesDiffs[payload.entity][payload.source];
      state.candidatesConsolidation.candidatesDiffs[payload.entity].target = [];
      state.candidatesConsolidation.candidatesDiffs[payload.entity].updates = [];
    })

    .addCase(mergeCandidateAction, (state, { payload: { source, blackListFields } }) => {
      if (!blackListFields.includes('candidateProfessions')) {
        state.candidatesConsolidation.candidatesDiffs.candidateProfessions.preselected =
          candidateNestedFieldsAutoMergeMapper({
            source,
            entity: 'candidateProfessions',
            state,
          });
        state.candidatesConsolidation.candidatesDiffs.candidateProfessions.target = [];
        state.candidatesConsolidation.candidatesDiffs.candidateProfessions.updates = [];
      }
      if (!blackListFields.includes('candidateLanguages')) {
        state.candidatesConsolidation.candidatesDiffs.candidateLanguages.preselected =
          candidateNestedFieldsAutoMergeMapper({
            source,
            entity: 'candidateLanguages',
            state,
          });
        state.candidatesConsolidation.candidatesDiffs.candidateLanguages.target = [];
        state.candidatesConsolidation.candidatesDiffs.candidateLanguages.updates = [];
      }
      if (!blackListFields.includes('candidateContacts')) {
        // ----- ADDITIONAL_PHONE -----
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.ADDITIONAL_PHONE.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateContacts.ADDITIONAL_PHONE?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.ADDITIONAL_PHONE.status =
          CandidateChangesStatuses.Resolved;

        // ----- PHONE -----
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.PHONE.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateContacts.PHONE?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.PHONE.status =
          CandidateChangesStatuses.Resolved;
        // ----- GITHUB -----
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.GITHUB.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateContacts.GITHUB?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.GITHUB.status =
          CandidateChangesStatuses.Resolved;

        // ----- LINKEDIN -----
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.LINKEDIN.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateContacts.LINKEDIN?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.LINKEDIN.status =
          CandidateChangesStatuses.Resolved;

        // ----- TELEGRAM -----
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.TELEGRAM.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateContacts.TELEGRAM?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.TELEGRAM.status =
          CandidateChangesStatuses.Resolved;

        // ----- EMAIL -----
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.EMAIL.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateContacts.EMAIL?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.EMAIL.status =
          CandidateChangesStatuses.Resolved;

        // ----- BEHANCE -----
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.BEHANCE.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateContacts.BEHANCE?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.BEHANCE.status =
          CandidateChangesStatuses.Resolved;

        // ----- SKYPE -----
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.SKYPE.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateContacts.SKYPE?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateContacts.SKYPE.status =
          CandidateChangesStatuses.Resolved;
      }
      if (!blackListFields.includes('candidateSalary')) {
        state.candidatesConsolidation.candidatesDiffs.candidateSalary.currency.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateSalary.currency?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryComment.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryComment?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryMax.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryMax?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryMin.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryMin?.[source];
        state.candidatesConsolidation.candidatesDiffs.candidateSalary.currency.status =
          CandidateChangesStatuses.Resolved;
        state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryComment.status =
          CandidateChangesStatuses.Resolved;
        state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryMax.status =
          CandidateChangesStatuses.Resolved;
        state.candidatesConsolidation.candidatesDiffs.candidateSalary.expectedSalaryMin.status =
          CandidateChangesStatuses.Resolved;
      }
      if (!blackListFields.includes('candidateLocation')) {
        state.candidatesConsolidation.candidatesDiffs.candidateLocation.currentLocationCity.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateLocation.currentLocationCity[source];
        state.candidatesConsolidation.candidatesDiffs.candidateLocation.currentLocationCountry.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateLocation.currentLocationCountry[source];
        state.candidatesConsolidation.candidatesDiffs.candidateLocation.locationCity.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateLocation.locationCity[source];
        state.candidatesConsolidation.candidatesDiffs.candidateLocation.locationCountry.preselected =
          state.candidatesConsolidation.candidatesDiffs.candidateLocation.locationCountry[source];
        state.candidatesConsolidation.candidatesDiffs.candidateLocation.currentLocationCity.status =
          CandidateChangesStatuses.Resolved;
        state.candidatesConsolidation.candidatesDiffs.candidateLocation.currentLocationCountry.status =
          CandidateChangesStatuses.Resolved;
        state.candidatesConsolidation.candidatesDiffs.candidateLocation.locationCity.status =
          CandidateChangesStatuses.Resolved;
        state.candidatesConsolidation.candidatesDiffs.candidateLocation.locationCountry.status =
          CandidateChangesStatuses.Resolved;
      }
      if (!(blackListFields.includes('source') && blackListFields.includes('sourceDetails'))) {
        state.candidatesConsolidation.candidatesDiffs.source.preselected =
          state.candidatesConsolidation.candidatesDiffs.source[source];
        state.candidatesConsolidation.candidatesDiffs.source.status = CandidateChangesStatuses.Resolved;
        state.candidatesConsolidation.candidatesDiffs.sourceDetails.preselected =
          state.candidatesConsolidation.candidatesDiffs.sourceDetails[source];
        state.candidatesConsolidation.candidatesDiffs.sourceDetails.status = CandidateChangesStatuses.Resolved;
      }
      if (!blackListFields.includes('vacancy')) {
        state.candidatesConsolidation.candidatesDiffs.candidatePrioritizedVacancies.preselected =
          candidateSelectDataFieldsMapper({
            state,
            source,
            entity: 'candidatePrioritizedVacancies',
          });
        state.candidatesConsolidation.candidatesDiffs.candidatePrioritizedVacancies.target = [];
        state.candidatesConsolidation.candidatesDiffs.candidatePrioritizedVacancies.updates = [];
        state.candidatesConsolidation.candidatesDiffs.candidatePrioritizedVacancies.status =
          CandidateChangesStatuses.Resolved;
      }
      selectDataFields.forEach((entity) => {
        if (!blackListFields.includes(entity)) {
          state.candidatesConsolidation.candidatesDiffs[entity].preselected = candidateSelectDataFieldsMapper({
            state,
            source,
            entity,
          });
          state.candidatesConsolidation.candidatesDiffs[entity].target = [];
          state.candidatesConsolidation.candidatesDiffs[entity].updates = [];
          state.candidatesConsolidation.candidatesDiffs[entity].status = CandidateChangesStatuses.Resolved;
        }
      });
      activityPlaceFields.forEach((entity) => {
        if (!blackListFields.includes(entity)) {
          state.candidatesConsolidation.candidatesDiffs[entity].preselected = candidateActivityPlaceMapper({
            state,
            entity,
            source,
          });
          state.candidatesConsolidation.candidatesDiffs[entity].target = [];
          state.candidatesConsolidation.candidatesDiffs[entity].updates = [];
        }
      });
      autoMergeCandidateFields.forEach((entity) => {
        if (!blackListFields.includes(entity)) {
          state.candidatesConsolidation.candidatesDiffs[entity].preselected =
            state.candidatesConsolidation.candidatesDiffs[entity][source];
          state.candidatesConsolidation.candidatesDiffs[entity].status = CandidateChangesStatuses.Resolved;
        }
      });
    })

    .addCase(getCandidateChangeHistoryThunk.fulfilled, (state, { payload }) => {
      if (payload.withComments.length) {
        state.history.idsWithComments = payload.withComments;
      }
      state.history.isLoading = false;
      state.history.items = { ...state.history.items, ...transformArrayToObject(payload.items.content) };
      state.history.ids = [...state.history.ids, ...getArrayIds(payload.items.content)];
      state.history.totalPages = payload.items.totalPages;
    })
    .addCase(getCandidateChangeHistoryThunk.pending, (state) => {
      state.history.isLoading = true;
    })
    .addCase(getCandidateChangeHistoryThunk.rejected, (state) => {
      state.history.isLoading = false;
    })
    .addCase(clearCandidateHistoryAction, (state) => {
      state.history = preloadedCandidatesState.history;
    })

    .addCase(duplicatesReportThunk.fulfilled, (state, { payload }) => {
      state.report.items = payload;
      state.report.isLoading = false;
    })
    .addCase(duplicatesReportThunk.pending, (state) => {
      state.report.isLoading = true;
    })
    .addCase(duplicatesReportThunk.rejected, (state) => {
      state.report = preloadedCandidatesState.report;
    })
    .addCase(clearDuplicatesReportAction, (state) => {
      state.report = preloadedCandidatesState.report;
    })
    .addCase(fetchCandidatesPairByIdThunk.pending, (state) => {
      state.report.isLoading = true;
    })
    .addCase(fetchCandidatesPairByIdThunk.fulfilled, (state) => {
      state.report.isLoading = false;
    })
    .addCase(fetchCandidatesPairByIdThunk.rejected, (state) => {
      state.report.isLoading = false;
    })
    .addCase(createHistoryCommentThunk.fulfilled, (state, { payload }) => {
      if (!state.history.idsWithComments.includes(payload.changeStoryGroupId)) {
        state.history.idsWithComments.push(payload.changeStoryGroupId);
      }
      state.history.items[payload.changeStoryGroupId].hasComments = true;
      state.history.items[payload.changeStoryGroupId].comments.isLoading = false;
      state.history.items[payload.changeStoryGroupId].comments.ids.push(payload.id);
      state.history.items[payload.changeStoryGroupId].comments.items[payload.id] = payload;
    })
    .addCase(createHistoryCommentThunk.pending, (state, { meta }) => {
      state.history.items[meta.arg.data.changeStoryGroupId].comments.isLoading = true;
    })
    .addCase(createHistoryCommentThunk.rejected, (state, { meta }) => {
      state.history.items[meta.arg.data.changeStoryGroupId].comments.isLoading = false;
    })
    .addCase(getHistoryCommentThunk.fulfilled, (state, { payload, meta }) => {
      state.history.items[meta.arg.changeStoryGroupId].comments.isLoading = false;
      if (payload.length) {
        state.history.items[meta.arg.changeStoryGroupId].comments.ids = getArrayIds(payload);
        state.history.items[meta.arg.changeStoryGroupId].comments.items = transformArrayToObject(payload);
      }
    })
    .addCase(getHistoryCommentThunk.pending, (state, { meta }) => {
      state.history.items[meta.arg.changeStoryGroupId].comments.isLoading = true;
    })
    .addCase(getHistoryCommentThunk.rejected, (state, { meta }) => {
      state.history.items[meta.arg.changeStoryGroupId].comments.isLoading = false;
    })
    .addCase(updateHistoryCommentThunk.fulfilled, (state, { payload }) => {
      state.history.items[payload.changeStoryGroupId].comments.isLoading = false;
      state.history.items[payload.changeStoryGroupId].comments.items[payload.id] = payload;
    })
    .addCase(updateHistoryCommentThunk.pending, (state, { meta }) => {
      state.history.items[meta.arg.data.changeStoryGroupId].comments.isLoading = true;
    })
    .addCase(updateHistoryCommentThunk.rejected, (state, { meta }) => {
      state.history.items[meta.arg.data.changeStoryGroupId].comments.isLoading = false;
    })
    .addCase(deleteHistoryCommentAction, (state, { payload }) => {
      state.history.items[payload.changeStoryGroupId].comments.deletedIds.push(payload.commentId);
    })
    .addCase(restoreHistoryCommentAction, (state, { payload }) => {
      state.history.items[payload.changeStoryGroupId].comments.deletedIds = state.history.items[
        payload.changeStoryGroupId
      ].comments.deletedIds.filter((item) => item !== payload.commentId);
    })
    .addCase(setRecruiterModeConfigAction, (state, { payload }) => {
      state.recruiterMode.isActive = true;
      state.recruiterMode.vacancyId = payload.vacancyId;
      state.recruiterMode.vacancyName = payload.vacancyName;
      if (payload.requestId) {
        state.recruiterMode.requestId = payload.requestId;
      }
    })
    .addCase(clearRecruiterCandidateAction, (state) => {
      state.recruiterMode = preloadedCandidatesState.recruiterMode;
    })
    .addCase(candidatesExportThunk.fulfilled, (state) => {
      state.candidates.isExportLoading = false;
    })
    .addCase(candidatesExportThunk.pending, (state) => {
      state.candidates.isExportLoading = true;
    })
    .addCase(candidatesExportThunk.rejected, (state) => {
      state.candidates.isExportLoading = false;
    })
    .addCase(restoreStatusItemByIdThunk.fulfilled, (state) => {
      state.allStatuses.isLoading = false;
    })
    .addCase(restoreStatusItemByIdThunk.pending, (state) => {
      state.allStatuses.isLoading = true;
    })
    .addCase(restoreStatusItemByIdThunk.rejected, (state) => {
      state.allStatuses.isLoading = false;
    })
    .addCase(deleteStatusItemByIdThunk.fulfilled, (state) => {
      state.allStatuses.isLoading = false;
    })
    .addCase(deleteStatusItemByIdThunk.pending, (state) => {
      state.allStatuses.isLoading = true;
    })
    .addCase(deleteStatusItemByIdThunk.rejected, (state) => {
      state.allStatuses.isLoading = false;
    })
    .addCase(getStatusListThunk.fulfilled, (state, { payload }) => {
      state.allStatuses.isLoading = false;
      state.allStatuses.items = transformArrayToObject(payload);
      state.allStatuses.ids = getArrayStringIds(payload);
    })
    .addCase(getStatusListThunk.pending, (state) => {
      state.allStatuses.isLoading = true;
    })
    .addCase(getStatusListThunk.rejected, (state) => {
      state.allStatuses.isLoading = false;
    })
    .addCase(clearStatusListAction, (state) => {
      state.allStatuses = preloadedCandidatesState.allStatuses;
    })
    .addCase(clearCurrentStatusAction, (state) => {
      state.currentStatus.form = preloadedCandidatesState.currentStatus.form;
    })
    .addCase(createCandidateStatusThunk.fulfilled, (state, { payload }) => {
      state.allStatuses.isLoading = false;
      state.events.ids.unshift(payload.id);
      state.events.items = { [payload.id]: payload, ...state.events.items };
      state.currentStatus.form = mapStatusItemResponseToStatusItem(payload);
      state.currentStatus.response = payload;
      const newLink = !state.candidates.currentCandidate.candidateLinks.find((item) => item.id === payload.link?.id);
      if (payload.link && newLink) {
        state.candidates.currentCandidate.candidateLinks.push(payload.link);
      }
    })
    .addCase(createCandidateStatusThunk.pending, (state) => {
      state.allStatuses.isLoading = true;
    })
    .addCase(createCandidateStatusThunk.rejected, (state) => {
      state.allStatuses.isLoading = false;
    })
    .addCase(updateCandidateStatusThunk.fulfilled, (state, { payload, meta }) => {
      state.allStatuses.isLoading = false;
      state.events.items[meta.arg.eventId] = payload;
      if (state.currentStatus.response.id === payload.id) {
        state.currentStatus.response = payload;
        state.currentStatus.form = mapStatusItemResponseToStatusItem(payload);
      }
      const newLink = !state.candidates.currentCandidate.candidateLinks.find((item) => item.id === payload.link?.id);
      if (payload.link && newLink) {
        state.candidates.currentCandidate.candidateLinks.push(payload.link);
      }
    })
    .addCase(updateCandidateStatusThunk.pending, (state) => {
      state.allStatuses.isLoading = true;
    })
    .addCase(updateCandidateStatusThunk.rejected, (state) => {
      state.allStatuses.isLoading = false;
    })
    .addCase(restoreAdminChangeCommentThunk.fulfilled, (state, { meta }) => {
      state.history.items[meta.arg.parentId].comments.isLoading = false;
      const newHistoryComments = state.history.items[meta.arg.parentId].comments.ids.map((item) =>
        item === meta.arg.commentId
          ? {
              ...state.history.items[meta.arg.parentId].comments.items[item],
              lifecycleStatus: LifecycleStatuses.Actual,
            }
          : state.history.items[meta.arg.parentId].comments.items[item],
      );
      state.history.items[meta.arg.parentId].comments.items = transformArrayToObject(newHistoryComments);
    })
    .addCase(restoreAdminChangeCommentThunk.pending, (state, { meta }) => {
      state.history.items[meta.arg.parentId].comments.isLoading = true;
    })
    .addCase(restoreAdminChangeCommentThunk.rejected, (state, { meta }) => {
      state.history.items[meta.arg.parentId].comments.isLoading = false;
    })
    .addCase(restoreAdminEventThunk.fulfilled, (state, { meta }) => {
      if (meta.arg.parentId) {
        state.events.items[meta.arg.parentId].comment.isLoading = false;
        const newThreadArray = state.events.items[meta.arg.parentId].comment.commentThread.map((item) =>
          item.id === meta.arg.eventId ? { ...item, lifecycleStatus: LifecycleStatuses.Actual } : item,
        );
        state.events.items[meta.arg.parentId].comment.commentThread = newThreadArray;
      } else {
        state.events.items[meta.arg.eventId].comment.isLoading = false;
        state.events.items[meta.arg.eventId].lifecycleStatus = LifecycleStatuses.Actual;

        const newActual = state.events.ids.filter(
          (item) =>
            item !== state.events.deletedIds.find((curr) => curr === item) &&
            state.events.items[item]?.status &&
            state.events.items[item]?.lifecycleStatus !== LifecycleStatuses.Deleted,
        )[0];
        if (newActual) {
          state.currentStatus.response = state.events.items[newActual];
          state.currentStatus.form = mapStatusItemResponseToStatusItem(state.events.items[newActual]);
        }
      }
    })
    .addCase(restoreAdminEventThunk.pending, (state, { meta }) => {
      if (meta.arg.parentId) {
        state.events.items[meta.arg.parentId].comment.isLoading = true;
      } else {
        state.events.isStatusAlone = false;
        state.events.items[meta.arg.eventId].comment.isLoading = true;
      }
    })
    .addCase(restoreAdminEventThunk.rejected, (state, { meta }) => {
      if (meta.arg.parentId) {
        state.events.items[meta.arg.parentId].comment.isLoading = false;
      } else {
        state.events.items[meta.arg.eventId].comment.isLoading = false;
      }
    })
    .addCase(updateCandidateThreadCommentThunk.fulfilled, (state, { payload, meta }) => {
      state.events.items[meta.arg.eventId].comment.commentThread = state.events.items[
        meta.arg.eventId
      ].comment.commentThread.map((item) => (item.id === payload.id ? payload : item));
      state.events.items[meta.arg.eventId].comment.isLoading = false;
    })
    .addCase(updateCandidateThreadCommentThunk.pending, (state, { meta }) => {
      state.events.items[meta.arg.eventId].comment.isLoading = true;
    })
    .addCase(updateCandidateThreadCommentThunk.rejected, (state, { meta }) => {
      state.events.items[meta.arg.eventId].comment.isLoading = false;
    })
    .addCase(clearCandidateEventsAction, (state) => {
      state.events = preloadedCandidatesState.events;
    })
    .addCase(getCandidateEventsThunk.fulfilled, (state, { payload }) => {
      state.events.isLoading = false;
      state.events.totalPages = payload.totalPages;

      if (payload.first) {
        const ids = getArrayIds(payload.content);
        const obj = transformArrayToObject(payload.content);
        state.events.ids = ids;
        state.events.items = obj;
        state.events.isStatusAlone =
          ids.filter((item) => obj[item].lifecycleStatus === LifecycleStatuses.Actual).length <= 1;

        const newActual = ids.filter(
          (item) =>
            item !== state.events.deletedIds.find((curr) => curr === item) &&
            state.events.items[item]?.status &&
            state.events.items[item]?.lifecycleStatus !== LifecycleStatuses.Deleted,
        )[0];
        if (newActual) {
          state.currentStatus.response = state.events.items[newActual];
          state.currentStatus.form = mapStatusItemResponseToStatusItem(state.events.items[newActual]);
        }
      } else {
        state.events.ids = [...state.events.ids, ...getArrayIds(payload.content)];
        state.events.items = { ...state.events.items, ...transformArrayToObject(payload.content) };
      }
    })
    .addCase(getCandidateEventsThunk.pending, (state) => {
      state.events.isLoading = true;
    })
    .addCase(getCandidateEventsThunk.rejected, (state) => {
      state.events.isLoading = false;
    })
    .addCase(createCandidateThreadCommentThunk.fulfilled, (state, { payload, meta }) => {
      state.events.items[meta.arg.eventId].comment.commentThread.push(payload);
      state.events.items[meta.arg.eventId].comment.isLoading = false;
      state.events.items[meta.arg.eventId].comment.editAllowed = false;
      state.events.items[meta.arg.eventId].comment.deleteAllowed = false;
    })
    .addCase(createCandidateThreadCommentThunk.pending, (state, { meta }) => {
      state.events.items[meta.arg.eventId].comment.isLoading = true;
    })
    .addCase(createCandidateThreadCommentThunk.rejected, (state, { meta }) => {
      state.events.items[meta.arg.eventId].comment.isLoading = false;
    })
    .addCase(deleteCommentAction, (state, { payload }) => {
      if (payload.parentId) {
        state.events.deletedThreadIds = [payload.commentId, ...state.events.deletedThreadIds];
      } else {
        if (payload.isLoading) state.events.isLoading = true;
        state.events.deletedIds = [...state.events.deletedIds, payload.commentId];
        if (state.currentStatus?.response?.id === payload.commentId) {
          const newActual = state.events.ids.filter(
            (item) =>
              item !== state.events.deletedIds.find((curr) => curr === item) &&
              state.events.items[item]?.status &&
              state.events.items[item].lifecycleStatus !== LifecycleStatuses.Deleted,
          )[0];
          if (newActual) {
            state.currentStatus.response = state.events.items[newActual];
            state.currentStatus.form = mapStatusItemResponseToStatusItem(state.events.items[newActual]);
          }
        }
      }
    })
    .addCase(restoreCommentAction, (state, { payload }) => {
      if (payload.parentId) {
        state.events.deletedThreadIds = state.events.deletedThreadIds.filter((item) => item !== payload.commentId);
      } else {
        state.events.deletedIds = state.events.deletedIds.filter((item) => item !== payload.commentId);
        const newActual = state.events.ids.filter(
          (item) =>
            item !== state.events.deletedIds.find((curr) => curr === item) &&
            state.events.items[item]?.status &&
            state.events.items[item].lifecycleStatus !== LifecycleStatuses.Deleted,
        )[0];
        if (newActual) {
          state.currentStatus.response = state.events.items[newActual];
          state.currentStatus.form = mapStatusItemResponseToStatusItem(state.events.items[newActual]);
        }
      }
    })
    .addCase(setCurrentStatusItemAction, (state, { payload }) => {
      if (state.events.items[payload.eventId]) {
        state.currentStatus.form = mapStatusItemResponseToStatusItem(state.events.items[payload.eventId]);
      }
    })
    .addCase(setPagableAction, (state, { payload }) => {
      if (payload?.currentPage) state.report.pagable.currentPage = payload.currentPage;
      if (payload?.pageSize) state.report.pagable.pageSize = payload.pageSize;
    })
    .addCase(resolveStatusClaimThunk.fulfilled, (state, { payload, meta }) => {
      if (state.events.items[meta.arg.eventId]) {
        state.events.items[meta.arg.eventId].comment.isLoading = false;
        state.events.items[meta.arg.eventId].claims = state.events.items[meta.arg.eventId].claims.map((item) =>
          item.id === meta.arg.claimId
            ? {
                ...item,
                status:
                  meta.arg.actionType === ClaimActionTypes.DECLINE
                    ? ClaimStatusTypes.DECLINED
                    : ClaimStatusTypes.RESOLVED,
              }
            : item,
        );
        if (payload) {
          state.candidates.currentCandidate = payload;
          state.events.items[meta.arg.eventId].file = payload.filesMeta.find((item) => item.id === meta.arg.file?.id);
        }
        if (state.currentStatus.response.id === meta.arg.eventId) {
          state.currentStatus.response = state.events.items[meta.arg.eventId];
          state.currentStatus.form = mapStatusItemResponseToStatusItem(state.events.items[meta.arg.eventId]);
        }
      }
    })
    .addCase(resolveStatusClaimThunk.pending, (state, { meta }) => {
      if (state.events.items[meta.arg.eventId]) {
        state.events.items[meta.arg.eventId].comment.isLoading = true;
      }
    })
    .addCase(resolveStatusClaimThunk.rejected, (state, { meta }) => {
      if (state.events.items[meta.arg.eventId]) {
        state.events.items[meta.arg.eventId].comment.isLoading = false;
      }
    })
    .addCase(prioritizeCandidatesThunk.fulfilled, (state) => {
      state.candidates.isLoading = false;
    })
    .addCase(prioritizeCandidatesThunk.pending, (state) => {
      state.candidates.isLoading = true;
    })
    .addCase(prioritizeCandidatesThunk.rejected, (state) => {
      state.candidates.isLoading = false;
    })
    .addCase(getPrioritizeCandidatesThunk.fulfilled, (state, { payload, meta }) => {
      const updatedItems = { ...state.candidates.items, ...transformArrayToObject(payload.content) };
      const updatedItemsIds = [...new Set([...state.candidates.ids, ...getArrayIds(payload.content)])];
      state.candidates.items = updatedItems;
      state.candidates.ids = updatedItemsIds;
      state.candidates.totalPages = payload.totalPages;
      if (meta.arg.data.params.boosted) {
        state.candidates.isPrioritizeLoading = false;
      } else {
        state.candidates.isNotPrioritizeLoading = false;
      }
    })
    .addCase(getPrioritizeCandidatesThunk.pending, (state, { meta }) => {
      if (meta.arg.data.params.boosted) {
        state.candidates.isPrioritizeLoading = true;
      } else {
        state.candidates.isNotPrioritizeLoading = true;
      }
    })
    .addCase(getPrioritizeCandidatesThunk.rejected, (state) => {
      state.candidates.isPrioritizeLoading = false;
      state.candidates.isNotPrioritizeLoading = false;
    })

    .addCase(getPrioritizeCandidatesByVacancyIdThunk.pending, (state) => {
      state.candidates.isPrioritizeLoading = true;
    })
    .addCase(getPrioritizeCandidatesByVacancyIdThunk.fulfilled, (state, { payload, meta }) => {
      state.candidates.isPrioritizeLoading = false;
      const updatedItems =
        meta.arg.params.page !== 1
          ? { ...state.candidates.items, ...transformArrayToObject(payload.content) }
          : transformArrayToObject(payload.content);
      const updatedItemsIds =
        meta.arg.params.page !== 1
          ? [...new Set([...state.candidates.ids, ...getArrayIds(payload.content)])]
          : getArrayIds(payload.content);
      state.candidates.items = updatedItems;
      state.candidates.ids = updatedItemsIds;
      state.candidates.totalPages = payload.totalPages;
    })
    .addCase(getPrioritizeCandidatesByVacancyIdThunk.rejected, (state) => {
      state.candidates.isPrioritizeLoading = false;
    })

    .addCase(updateVacancyCandidatePriorityThunk.fulfilled, (state, { meta }) => {
      state.candidates.items[meta.arg.candidateId].isBoosted = false;
    })
    .addCase(clearActualStatusAction, (state) => {
      state.currentStatus.form = preloadedCandidatesState.currentStatus.form;
    })
    .addCase(getCandidateActualStatusThunk.fulfilled, (state, { payload }) => {
      state.allStatuses.isLoading = false;
      state.currentStatus.form = mapStatusItemResponseToStatusItem(payload);
      state.currentStatus.response = payload;
    })
    .addCase(getCandidateActualStatusThunk.pending, (state) => {
      state.allStatuses.isLoading = true;
    })
    .addCase(getCandidateActualStatusThunk.rejected, (state) => {
      state.allStatuses.isLoading = false;
    })
    .addCase(deleteCandidateCommentsThunk.fulfilled, (state, { payload }) => {
      state.candidates.currentCandidate = payload;
      state.events.deletedIds = [];
      state.events.deletedThreadIds = [];
    })
    .addCase(fetchCandidateRequestClaimThunk.fulfilled, (state, { payload }) => {
      state.claim.isLoading = false;
      state.claim.withRequestClaim = payload.withRequestClaim;
    })
    .addCase(fetchCandidateRequestClaimThunk.pending, (state) => {
      state.claim.isLoading = true;
    })
    .addCase(fetchCandidateRequestClaimThunk.rejected, (state) => {
      state.claim.isLoading = false;
    })
    .addCase(clearCandidatesOffersAction, (state) => {
      state.offers = preloadedCandidatesState.offers;
    })
    .addCase(clearCandidatesClaimAction, (state) => {
      state.claim = preloadedCandidatesState.claim;
    })
    .addCase(saveStatusesOrdersThunk.fulfilled, (state, { payload }) => {
      state.allStatuses.isLoading = false;
      state.allStatuses.ids = payload;
      state.allStatuses.items = payload.reduce((acc, val, index) => {
        acc[val] = { ...acc[val], orderValue: index };
        return acc;
      }, state.allStatuses.items);
    })
    .addCase(saveStatusesOrdersThunk.pending, (state) => {
      state.allStatuses.isLoading = true;
    })
    .addCase(saveStatusesOrdersThunk.rejected, (state) => {
      state.allStatuses.isLoading = false;
    })
    .addCase(logoutAction, () => preloadedCandidatesState),
);
