import { CandidateLifecycleStatuses, CurrencyEnum, IntervalFiltersType, SortingValue } from '@constants';
import {
  CandidateFileItemResponse,
  CandidateHistoryCommentItemResponse,
  CandidateHistoryResponse,
  CandidateItemResponse,
  CandidateParsedPdfInfo,
  CandidateReportItem,
  HistoryCommentItem,
  GetAllCandidates,
  ListItemsResponse,
  ShortCandidateItemResponse,
  IntervalFiltersValues,
  CandidatesDuplicatesStatuses,
  CreateCandidatesDuplicatesResponse,
} from '@innowise-group/core';
import type { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';
import qs from 'qs';

export default class CandidatesService {
  static async checkCheckCandidateDuplicates(data: object): Promise<AxiosResponse<CandidateItemResponse[]>> {
    return $api.post<CandidateItemResponse[]>('/recruiting-tool/api/v1/candidate-duplicates/check-candidate', data);
  }

  static async candidateDuplicatesCreate(data: {
    candidateId: number | string;
    duplicateIds: (string | number)[];
  }): Promise<AxiosResponse<any>> {
    return $api.post<any>('/recruiting-tool/api/v1/candidate-duplicates/create', {
      ...data,
      duplicateStatus: CandidatesDuplicatesStatuses.NotDuplicate,
    });
  }

  static async createCandidate(data: object): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/candidates`, data);
  }

  static async updateCandidate(data: object, id: number): Promise<AxiosResponse> {
    return $api.put('/recruiting-tool/api/v1/candidates', { ...data, id });
  }

  static async createCandidateDuplicates(data: CreateCandidatesDuplicatesResponse): Promise<void> {
    return $api.post('/recruiting-tool/api/v1/candidate-duplicates/create', { ...data });
  }

  static async restoreCandidateById(ids: number[]): Promise<AxiosResponse> {
    return $api.post('/recruiting-tool/api/v1/candidates/restore', ids);
  }

  static async candidateSearch(args: {
    params?: {
      currentPage: number;
      pageSize?: number;
      sort?: SortingValue;
      vacancyId?: string;
      boosted?: boolean;
      isRecruiterMode?: boolean;
    };
    body?: object;
  }): Promise<AxiosResponse<GetAllCandidates>> {
    const { body, params } = args;
    const sortItems = params?.sort?.split('%');
    const newData = {
      page: params?.currentPage,
      size: params?.pageSize,
      sort: sortItems,
    };
    return $api.post<GetAllCandidates>(
      `/recruiting-tool/api/v1/candidates/search`,
      { ...body },
      { params: newData, paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) },
    );
  }

  static async getCandidateById(id: number): Promise<AxiosResponse<CandidateItemResponse>> {
    return $api.get<CandidateItemResponse>(`/recruiting-tool/api/v1/candidates/${id}`);
  }

  static async getCandidateByIds(args: (number | string)[]): Promise<AxiosResponse<CandidateItemResponse[]>> {
    return $api.post<CandidateItemResponse[]>('/recruiting-tool/api/v1/candidates/getByIdIn', args);
  }

  static async deleteCandidateById(id: number, isSoftDelete: boolean): Promise<AxiosResponse> {
    return $api.delete(`/recruiting-tool/api/v1/candidates/${id}?isSoftDelete=${isSoftDelete}`);
  }

  static async addFilesLinks(
    candidateId: number,
    candidateFileIds: number[],
  ): Promise<AxiosResponse<CandidateFileItemResponse[]>> {
    return $api.post(`/recruiting-tool/api/v1/candidate-files/attach`, { candidateId, candidateFileIds });
  }

  static async getFilesIds(
    candidateId: number,
    candidateLifecycleStatus: CandidateLifecycleStatuses,
  ): Promise<AxiosResponse<CandidateFileItemResponse[]>> {
    return $api.get<CandidateFileItemResponse[]>(
      `/recruiting-tool/api/v1/candidate-files/candidate?candidateId=${candidateId}&candidateLifecycleStatus=${candidateLifecycleStatus}`,
    );
  }

  static async deleteLinkedFile(fileId: number, isSoftDelete: boolean): Promise<AxiosResponse> {
    return $api.delete(`/recruiting-tool/api/v1/candidate-files/${fileId}?isSoftDelete=${isSoftDelete}`);
  }

  static async getParsedPdfInfo(file: FormData): Promise<AxiosResponse<CandidateParsedPdfInfo>> {
    return $api.post(`/recruiting-tool/api/v1/parsing/candidate`, file);
  }

  static async getChangeHistoryByCandidate(
    candidateId: number,
    page: number,
  ): Promise<AxiosResponse<CandidateHistoryResponse>> {
    return $api.post(`/recruiting-tool/api/v1/changestory/search?sort=modified,desc&page=${page}`, {
      entityId: candidateId,
      entityName: 'Candidate',
    });
  }

  static async getDuplicatesReport(params: {
    page: number;
    size: number;
  }): Promise<AxiosResponse<ListItemsResponse<CandidateReportItem>>> {
    return $api.get<ListItemsResponse<CandidateReportItem>>('/recruiting-tool/api/v1/candidate-duplicates', { params });
  }

  static async resolveCandidateDuplicates(params: { groupId: number }): Promise<AxiosResponse<void>> {
    return $api.post<void>('/recruiting-tool/api/v1/candidate-duplicates/resolve', null, { params });
  }

  static async mergeCandidateDuplicates(duplicateGroupIds: {
    groupId: string;
    candidateDuplicateId: string;
    validCandidate: object;
  }): Promise<AxiosResponse<void>> {
    return $api.post<void>('/recruiting-tool/api/v1/candidate-duplicates/merge', { ...duplicateGroupIds });
  }

  static async getChangeHistoryComments(params: {
    changeStoryGroupId: number;
  }): Promise<AxiosResponse<CandidateHistoryCommentItemResponse[]>> {
    return $api.get(`/recruiting-tool/api/v1/change-story-comment`, { params });
  }

  static async createChangeHistoryComment(
    data: HistoryCommentItem,
  ): Promise<AxiosResponse<CandidateHistoryCommentItemResponse>> {
    return $api.post(`/recruiting-tool/api/v1/change-story-comment`, data);
  }

  static async updateChangeHistoryComment(data: object): Promise<AxiosResponse<CandidateHistoryCommentItemResponse>> {
    return $api.put(`/recruiting-tool/api/v1/change-story-comment`, data);
  }

  static async deleteChangeHistoryComment(commentId: number): Promise<AxiosResponse> {
    return $api.delete(`/recruiting-tool/api/v1/change-story-comment/${commentId}`);
  }

  static async restoreChangeHistoryComment(ids: number[]): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/change-story-comment/restore`, ids);
  }

  static async getChangeHistoryWithCommentsIds(params: { candidateId: number }): Promise<AxiosResponse<number[]>> {
    return $api.get('/recruiting-tool/api/v1/change-story-comment/groups', { params });
  }

  static async candidatesExportStartJob(args: {
    body: object;
    params: { page: number; size: number };
  }): Promise<AxiosResponse<{ id: number; status: string; fileId: number }>> {
    const { body, params } = args;
    return $api.post(
      '/recruiting-tool/api/v1/candidates/export',
      { ...body },
      {
        responseType: 'json',
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      },
    );
  }

  static async candidatesExportGetStatus(id: number) {
    return $api.get(`/recruiting-tool/api/v1/export-task/${id}`);
  }

  static async candidatesExportGetFile(id: number) {
    return $api.get(`/recruiting-tool/api/v1/attachment?id=${id}`, { responseType: 'blob' });
  }

  static async getCountryByPhone(phone: string): Promise<AxiosResponse<{ isoCode: string }>> {
    return $api.get(`/recruiting-tool/api/v1/countries/getByPhone?language=ENGLISH&phone=${phone}`);
  }

  static async prioritizeCandidates(
    selectedCandidates: number[],
    vacancyId: number,
    boost: boolean,
  ): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/candidates/prioritize`, {
      candidateIds: selectedCandidates,
      vacancyId,
      boost,
    });
  }

  static async getPrioritizedCandidates(
    args: {
      params?: {
        currentPage: number;
        pageSize?: number;
        sort?: SortingValue;
        vacancyId?: string;
        boosted?: boolean;
        isRecruiterMode?: boolean;
        includeVacancyRequestFilter?: boolean;
      };
      body?: object;
    },
    controller?: AbortController,
  ): Promise<AxiosResponse<ListItemsResponse<ShortCandidateItemResponse>>> {
    const { body, params } = args;
    const sortItems = params?.sort?.split('%');
    const newData = {
      page: params?.currentPage,
      size: params?.pageSize,
      vacancyId: params?.vacancyId,
      includeVacancyRequestFilter: params?.includeVacancyRequestFilter,
      sort: sortItems,
      boosted: params?.boosted,
    };
    return $api.post<ListItemsResponse<ShortCandidateItemResponse>>(
      `/recruiting-tool/api/v1/candidates/getOrderedByPriorityToVacancy`,
      body,
      {
        params: newData,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        signal: controller?.signal,
      },
    );
  }

  static async getPrioritizeCandidatesByVacancyId(
    params: {
      vacancyId: string;
      size?: number;
      page?: number;
      sort?: SortingValue;
    },
    controller?: AbortController,
  ): Promise<AxiosResponse<ListItemsResponse<ShortCandidateItemResponse>>> {
    const sortItems = params?.sort?.split('%');
    const newData = {
      page: params?.page,
      size: params?.size,
      vacancyId: params?.vacancyId,
      sort: sortItems,
    };
    return $api.post<ListItemsResponse<ShortCandidateItemResponse>>(
      '/recruiting-tool/api/v1/candidates/vacancy/prioritized',
      null,
      {
        params: newData,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        signal: controller?.signal,
      },
    );
  }

  static async getInitialCandidatesFilter(
    params:
      | {
          intervalType: IntervalFiltersType.Salary;
          currency: CurrencyEnum;
        }
      | {
          intervalType: Exclude<IntervalFiltersType, IntervalFiltersType.Salary>;
        },
  ): Promise<AxiosResponse<IntervalFiltersValues>> {
    return $api.post<IntervalFiltersValues>(`/recruiting-tool/api/v1/candidates/filter`, null, {
      params,
    });
  }

  static async mergeMany(args: {
    originalCandidateId: number;
    duplicateCandidateIds: number[];
  }): Promise<AxiosResponse<void>> {
    return $api.post('/recruiting-tool/api/v1/candidate-duplicates/merge/many', { ...args });
  }
}
