import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  MyKnownError,
  EmployeesService,
  VacanciesService,
  RequestsService,
  FullEmployeeRequest,
  RolesService,
  DictionaryService,
  ReferralService,
  MergeLocationsItem,
  AdministrationStatusItem,
  AdministrationStatusItemResponse,
  AppReducerType,
  ReferralItemResponse,
  LocalizedShortEmployeeItemResponse,
  ShortEmployeeItemResponse,
  UnitsService,
  UnitItem,
  ListItemsResponse,
  SourcingItemResponse,
  SourcingItem,
  StatusesService,
  AdministrationLocationSelectOptions,
} from '@innowise-group/core';
import {
  citiesMapper,
  newEmployeeMapper,
  referralMapper,
  requestsOptionsMapper,
  searchEmployeesThunkMapper,
  vacanciesOptionsMapper,
} from './administration.mappers';
import { AppNameLanguages, LifecycleStatuses, RolesValues, SortingValue, StatusLifecycleStatuses } from '@constants';
import { SelectOption } from '@innowise-group/core';
import { AxiosResponse } from 'axios';
import { localizedNameObject } from '@innowise-group/utilities';

export const searchEmployeesThunk = createAsyncThunk<
  ReturnType<typeof searchEmployeesThunkMapper>,
  Parameters<typeof EmployeesService.searchEmployees>['0'],
  { rejectValue: MyKnownError }
>('administration/searchEmployees', async (arg, { rejectWithValue }) => {
  try {
    const { data } = await EmployeesService.searchEmployees(arg);
    return searchEmployeesThunkMapper(data);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateEmployeeAuthDataThunk = createAsyncThunk<
  number,
  Parameters<typeof EmployeesService.updateEmployeeAuthData>['0'],
  { rejectValue: MyKnownError }
>('administration/updateEmployeeAuthData', async (arg, { rejectWithValue }) => {
  try {
    const response = await EmployeesService.updateEmployeeAuthData(arg);
    return response?.status;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const searchVacancyOptionsThunk = createAsyncThunk<SelectOption[], string, { rejectValue: MyKnownError }>(
  'administration/searchVacancyOptions',
  async (searchBarRequest, { rejectWithValue }) => {
    if (!searchBarRequest) return [];
    try {
      const { data } = await VacanciesService.searchAllVacancies({
        params: { currentPage: 1, pageSize: 10, sort: SortingValue.VacancyNameASC },
        body: { lifecycleStatus: LifecycleStatuses.Actual, searchBarRequest },
      });
      return vacanciesOptionsMapper(data.content);
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const searchRequestOptionsThunk = createAsyncThunk<SelectOption[], string, { rejectValue: MyKnownError }>(
  'administration/searchRequestOptions',
  async (searchBarValue, { rejectWithValue }) => {
    if (!searchBarValue) return [];
    try {
      const { data } = await RequestsService.getAllRequests({
        params: { currentPage: 1, pageSize: 10, sort: SortingValue.IdASC },
        body: { lifecycleStatus: LifecycleStatuses.Actual, searchBarValue },
      });
      return requestsOptionsMapper(data.content);
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const getEmployeeFromHrmByEmailThunk = createAsyncThunk<
  FullEmployeeRequest,
  Parameters<typeof EmployeesService.getEmployeeFromHrmByEmail>[0],
  { rejectValue: MyKnownError }
>('administration/getEmployeeFromHrmByEmail', async (email, { rejectWithValue }) => {
  try {
    const { data } = await EmployeesService.getEmployeeFromHrmByEmail(email);
    return newEmployeeMapper(data);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const addEmployeeThunk = createAsyncThunk<
  Awaited<ReturnType<typeof EmployeesService.createEmployee>>,
  Parameters<typeof EmployeesService.createEmployee>[0],
  { rejectValue: MyKnownError }
>('administration/createEmployee', async (employee, { rejectWithValue }) => {
  try {
    if (employee.lifecycleStatus === LifecycleStatuses.Deleted) {
      await EmployeesService.restoreEmployee(employee.id);
    } else {
      await EmployeesService.createEmployee(employee);
    }
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const searchEmailByEmailPartThunk = createAsyncThunk<
  Awaited<ReturnType<typeof EmployeesService.searchEmailByEmailPart>>['data'],
  Parameters<typeof EmployeesService.searchEmailByEmailPart>[0],
  { rejectValue: MyKnownError }
>('administration/searchEmailByEmailPart', async (params, { rejectWithValue }) => {
  try {
    const { data } = await EmployeesService.searchEmailByEmailPart(params);
    return data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const rolePermissionAssignThunk = createAsyncThunk<
  Awaited<ReturnType<typeof RolesService.rolePermissionAssign>>['data'],
  Parameters<typeof RolesService.rolePermissionAssign>[0],
  { rejectValue: MyKnownError }
>('administration/rolePermissionAssign', async (body, { rejectWithValue }) => {
  try {
    const { data } = await RolesService.rolePermissionAssign(body);
    return data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const deleteEmployeeThunk = createAsyncThunk<
  Awaited<ReturnType<typeof EmployeesService.deleteEmployee>>['data'],
  Parameters<typeof EmployeesService.deleteEmployee>[0],
  { rejectValue: MyKnownError }
>('administration/deleteEmployee', async (id, { rejectWithValue }) => {
  try {
    const { data } = await EmployeesService.deleteEmployee(id);
    return data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const fetchCitiesByLanguagesThunk = createAsyncThunk<
  { [key: string]: AdministrationLocationSelectOptions[] },
  undefined,
  { rejectValue: MyKnownError }
>('administration/getCitiesByLanguages', async (data, { rejectWithValue }) => {
  try {
    const arr: { promise: Promise<AxiosResponse>; hasCompanyOffice: boolean }[] = [];
    Object.keys(AppNameLanguages).map((key) => {
      arr.push({
        promise: DictionaryService.getCitySelectOptions({ hasCompanyOffice: false }, key),
        hasCompanyOffice: false,
      });
      arr.push({
        promise: DictionaryService.getCitySelectOptions({ hasCompanyOffice: true }, key),
        hasCompanyOffice: true,
      });
    });
    const response = await Promise.all(arr.map((obj) => obj.promise));
    return response.reduce((acc, { data, config }, index) => {
      const key = config.headers['Accept-Language'];
      const items = citiesMapper(data).map((item) => {
        return { ...item, hasCompanyOffice: arr[index].hasCompanyOffice };
      });
      if (acc[key]) {
        return {
          ...acc,
          [key]: [...acc[key], ...items],
        };
      }
      acc = {
        ...acc,
        [key]: items,
      };
      return acc;
    }, {});
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const mergeLocationsThunk = createAsyncThunk<void, { data: MergeLocationsItem }, { rejectValue: MyKnownError }>(
  'administration/mergeLocations',
  async ({ data }, { rejectWithValue }) => {
    try {
      const newData = {
        citiesForDelete:
          data.correctCityValue === 'null'
            ? data.cities.slice(1, data.cities.length).map((item) => item.value)
            : data.cities.reduce((acc, val) => {
                if (val.value !== data.correctCityValue) {
                  acc.push(val.value);
                }
                return acc;
              }, []),
        cityUpdate: {
          countryId: data.countryId,
          names: data.cityNames,
          translationUnionTag: data.correctCityValue === 'null' ? data.cities[0].value : data.correctCityValue,
        },
      };
      await DictionaryService.mergeLocations(newData);
      return;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const createStatusThunk = createAsyncThunk<
  void,
  { data: AdministrationStatusItem },
  { rejectValue: MyKnownError }
>('administration/createStatus', async ({ data }, { rejectWithValue }) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { statusName, subStatusNames, deletedSubStatusNames, group, ...formItems } = data;
    const subStatuses = subStatusNames.reduce((acc, { names }) => {
      if (!Object.values(names).some((val) => !val)) {
        acc.push(names);
      }
      return acc;
    }, []);

    const newData = {
      statusName,
      group,
      subStatusNames: formItems.SUB_STATUS.isExist ? subStatuses : [],
      formItem: Object.entries(formItems).reduce((acc, [key, val]) => {
        if (val.isExist) {
          if (key === 'FEEDBACK') {
            acc = { ...acc, [key]: val.isMandatory, FILE: false };
            return acc;
          }
          if (key === 'subStatus') {
            acc = { ...acc, ['SUB_STATUS']: val.isMandatory };
            return acc;
          }
          acc = { ...acc, [key]: val.isMandatory };
        }
        return acc;
      }, {}),
    };
    await DictionaryService.createStatus(newData);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getStatusItemByIdThunk = createAsyncThunk<
  AdministrationStatusItemResponse,
  { id: string },
  { rejectValue: MyKnownError }
>('administration/getStatusById', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await DictionaryService.getStatusById(id);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateStatusThunk = createAsyncThunk<
  void,
  { id: string; data: AdministrationStatusItem },
  { rejectValue: MyKnownError }
>('administration/updateStatus', async ({ id, data }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as AppReducerType;
    const currentStatus = state.administration.statuses.currentStatus;

    const statusesToBeCreated = data.subStatusNames.filter((status) => !status.id);

    const statusesToBeDeleted = data.deletedSubStatusNames.filter((deletedStatus) =>
      currentStatus.subStatus.find(
        (currentSubStatus) =>
          currentSubStatus.id === deletedStatus.id &&
          currentSubStatus.lifecycleStatus !== StatusLifecycleStatuses.Deleted,
      ),
    );

    const statusesToBeRestored = currentStatus.subStatus.filter((initialSubStatus) => {
      const isInitiallyDeleted = initialSubStatus.lifecycleStatus === StatusLifecycleStatuses.Deleted;

      if (isInitiallyDeleted) {
        return data.subStatusNames.some(
          (editedSubStatus) =>
            editedSubStatus.id === initialSubStatus.id &&
            editedSubStatus.lifecycleStatus !== StatusLifecycleStatuses.Deleted,
        );
      }

      return false;
    });

    const statusesToBeUpdated = data.subStatusNames.filter((editedSubStatus) => {
      if (editedSubStatus.lifecycleStatus === StatusLifecycleStatuses.Deleted) {
        return false;
      }

      const initialSubStatus = currentStatus.subStatus.find((subStatus) => subStatus.id === editedSubStatus.id);

      if (
        initialSubStatus &&
        (initialSubStatus.localizedNames.ENGLISH !== editedSubStatus.names.ENGLISH ||
          initialSubStatus.localizedNames.RUSSIAN !== editedSubStatus.names.RUSSIAN)
      ) {
        return true;
      }
    });

    if (statusesToBeCreated.length) {
      const arr: Promise<AxiosResponse>[] = [];
      statusesToBeCreated.map(({ names }) =>
        arr.push(DictionaryService.addSubStatus({ parentStatusId: currentStatus.id, names })),
      );
      await Promise.all(arr);
    }

    if (statusesToBeDeleted.length) {
      const arr: Promise<AxiosResponse>[] = [];
      statusesToBeDeleted.map(({ id }) => arr.push(DictionaryService.deleteStatusById(id)));
      await Promise.all(arr);
    }

    if (statusesToBeRestored.length) {
      const arr: Promise<AxiosResponse>[] = [];
      statusesToBeRestored.map(({ id }) => arr.push(DictionaryService.restoreStatusById(id)));
      await Promise.all(arr);
    }

    if (statusesToBeUpdated.length) {
      const arr: Promise<AxiosResponse>[] = [];
      statusesToBeUpdated.map(({ id, names }) => arr.push(DictionaryService.updateStatusName(id, names)));
      await Promise.all(arr);
    }

    await DictionaryService.updateStatusName(id, data.statusName);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const deleteStatusItemByIdThunk = createAsyncThunk<
  void,
  { id: string; priority: number },
  { rejectValue: MyKnownError }
>('administration/deleteStatusById', async ({ id, priority }, { rejectWithValue }) => {
  try {
    await DictionaryService.deleteStatusById(id);
    await StatusesService.setStatusesOrders({ [id]: priority });
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const restoreStatusItemByIdThunk = createAsyncThunk<
  void,
  { id: string; priority: number },
  { rejectValue: MyKnownError }
>('administration/restoreStatusById', async ({ id, priority }, { rejectWithValue }) => {
  try {
    await DictionaryService.restoreStatusById(id);
    await StatusesService.setStatusesOrders({ [id]: priority });
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getReferralDataThunk = createAsyncThunk<
  ReferralItemResponse[],
  Parameters<typeof ReferralService.getReferrals>[0],
  { rejectValue: MyKnownError }
>('administration/getReferralData', async (data, { rejectWithValue }) => {
  try {
    const response = await ReferralService.getReferrals(data);
    return referralMapper(response.data);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getReferralsManagerThunk = createAsyncThunk<
  LocalizedShortEmployeeItemResponse,
  undefined,
  { rejectValue: MyKnownError }
>('administration/getReferralsManager', async (data, { rejectWithValue }) => {
  try {
    const response = await EmployeesService.getReferralsManager();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateReferralsManagerThunk = createAsyncThunk<
  LocalizedShortEmployeeItemResponse,
  Parameters<typeof EmployeesService.updateReferralsManager>[0],
  { rejectValue: MyKnownError }
>('administration/updateReferralsManager', async (data, { rejectWithValue }) => {
  try {
    const response = await EmployeesService.updateReferralsManager(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const resolveReferralStatusThunk = createAsyncThunk<
  { yearMonth: string; id: string; accepted: boolean },
  { yearMonth: string; id: string; accepted: boolean },
  { rejectValue: MyKnownError }
>('administration/resolveReferralStatus', async ({ yearMonth, id, accepted }, { rejectWithValue }) => {
  try {
    await ReferralService.resolveReferralStatus(id, accepted);
    return { yearMonth, id, accepted };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getReferralManagerOptionsThunk = createAsyncThunk<
  ShortEmployeeItemResponse[],
  { roles: RolesValues[] },
  { rejectValue: MyKnownError }
>('administration/getReferralManagerOptions', async ({ roles }, { rejectWithValue }) => {
  try {
    const response = await EmployeesService.searchEmployeesByRoles(roles);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const exportReferralDataThunk = createAsyncThunk<
  undefined,
  Parameters<typeof ReferralService.exportReferrals>[0],
  { rejectValue: MyKnownError }
>('administration/exportReferralData', async (args, { rejectWithValue }) => {
  try {
    const response = await ReferralService.exportReferrals(args);
    const data = URL.createObjectURL(response.data);
    const fileUrl = document.createElement('a');
    fileUrl.href = data;
    fileUrl.download = 'export-referral.xlsx';
    document.body.appendChild(fileUrl);
    fileUrl.click();
    URL.revokeObjectURL(fileUrl.href);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getAllUnitsThunk = createAsyncThunk<UnitItem[], undefined, { rejectValue: MyKnownError }>(
  'administration/getAllUnits',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UnitsService.getAllUnits();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const getActualUnitsThunk = createAsyncThunk<UnitItem[], void, { rejectValue: MyKnownError }>(
  'administration/getActualUnits',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UnitsService.getActualUnits();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const createUnitThunk = createAsyncThunk<UnitItem, UnitItem, { rejectValue: MyKnownError }>(
  'administration/createUnit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UnitsService.createUnit(data);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const updateUnitThunk = createAsyncThunk<UnitItem, UnitItem, { rejectValue: MyKnownError }>(
  'administration/updateUnit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UnitsService.updateUnit(data);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const getAllSourcingDataThunk = createAsyncThunk<
  ListItemsResponse<SourcingItemResponse>,
  Parameters<typeof EmployeesService.getSourcingData>[0],
  { rejectValue: MyKnownError }
>('administration/getAllSourcingData', async (data, { rejectWithValue }) => {
  try {
    const response = await EmployeesService.getSourcingData(data);
    return {
      ...response.data,
      content: response.data.content.map((item) => {
        return { ...item, ...localizedNameObject(item) };
      }),
    };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const createSourcingEmployeeThunk = createAsyncThunk<
  void,
  Parameters<typeof EmployeesService.createSourcingEmployee>[0],
  { rejectValue: MyKnownError }
>('administration/createSourcingEmployee', async (data, { rejectWithValue }) => {
  try {
    await EmployeesService.createSourcingEmployee(data);
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateSourcingEmployeeThunk = createAsyncThunk<
  SourcingItem,
  Parameters<typeof EmployeesService.createSourcingEmployee>[0],
  { rejectValue: MyKnownError }
>('administration/updateSourcingEmployee', async (data, { rejectWithValue }) => {
  try {
    await EmployeesService.createSourcingEmployee(data);
    return data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
